.btnPadrao, 
.btnPadraoPreto,
.btnPadraoPeq, 
.btnPadraoPretoPeq {
  display: flex;
  width: 284px;
  height: 69px;
  background: var(--color-1) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: left;
  color: #ffffff;
  opacity: 1;
  font-size: 25px;
  justify-content: space-between;
  align-self: center;
  padding: 16px 25px 10px 25px;
  cursor: pointer;
  border: none; 
}
.btnPadraoPreto, .btnPadraoPretoPeq{
  background: var(--color-black-1) 0% 0% no-repeat padding-box;
}
.btnPadrao:hover, .btnPadraoPeq:hover {
  background: var(--color-2) 0% 0% no-repeat padding-box;
}
.btnPadraoPreto:hover, .btnPadraoPretoPeq:hover{
  background: var(--color-black-2) 0% 0% no-repeat padding-box;
}
/* Button small */
.btnPadraoPeq, 
.btnPadraoPretoPeq
{
  width: 184px;
  height: 49px;
  padding: 10px 15px 5px 15px;
  font-size: 20px;
}

/* Button icon */
.btnPadrao .imgBtn, 
.btnPadrao .imgBtnPadrao, 
.btnPadraoPreto .imgBtn,
.btnPadraoPreto .imgBtnPadrao,
.btnPadraoPeq .imgBtn, 
.btnPadraoPeq .imgBtnPadrao, 
.btnPadraoPretoPeq .imgBtn,
.btnPadraoPretoPeq .imgBtnPadrao 
{
  align-self: center;
  text-align: right;
  margin:0;
  position: relative;
  left:0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;  
}
.btnPadrao:hover .imgBtn, 
.btnPadrao:hover .imgBtnPadrao, 
.btnPadraoPreto:hover .imgBtn,
.btnPadraoPreto:hover .imgBtnPadrao {
  left: 10px;  
}


@media only screen and (max-width: 800px) {
  
  .btnPadraoPreto, 
  .btnPadrao {
    width: 360px;
  }
  .btnPadraoPretoPeq, 
  .btnPadraoPeq {
    width: 250px;
  }  
}