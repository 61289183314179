.container-principal-SearchingDriver {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  background-image: url(../../assets/SearchingDriver/map.svg);
  background-size: cover;
}

.container-SearchingDriver {
  margin: 15px;
}

.container-central-SearchingDriver {
  width: 586px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 10%;
  max-width: 100%;
  position: relative;
  padding-top: 50px;
  box-shadow: 0 0 10px #0000002e;
  padding-bottom: 80px;
}
.numero-pedido-SearchingDriver {
  max-width: 494px;
  height: 62px;
  background: #f5f2f2 0% 0% no-repeat padding-box;
  border-radius: 5px;
  margin: 0 auto;
  top: -30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: relative;
}
.numero-pedido-SearchingDriver h2 {
  color: #ff5e00;
}
.numero-pedido-SearchingDriver input {
  color: #303030;
  font-size: 28px;
  max-width: 100px;
  border: none;
  background: transparent;
}
.btn-acompanhar {
  display: flex;
  width: 284px;
  height: 53px;
  background: #303030;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  color: #ffffff;
  padding: 8px 6px 0px 10px;
  cursor: pointer;
  text-align: left;
  /* font: italic normal 23px/31px Segoe TV; */
  font-size: 23px;
  letter-spacing: 0px;
}

.btn-acompanhar:hover {
  border: 1.5px solid #e3e3e3;
}
.conteudo-searchingDriver {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.conteudo-searchingDriver h2 {
  /* font: italic normal 600 24px Segoe TV; */
  color: #ff5e00;
  margin-top: 20px;
  margin-bottom: 10px;
}
.conteudo-searchingDriver h3 {
  color: var(--unnamed-color-303030);
  /* font: italic normal 600 16px/21px Segoe TV; */
  color: #303030;
}

.conteudo-searchingDriver p {
  /* font: italic normal 600 12px Segoe TV; */
  font-size: 12px;
  color: #838383;
  margin-top: 10px;
  margin-bottom: 40px;
  font-weight: bold;
}
.conteudo-searchingDriver h4 {
  color: #838383;
}
.buttons-searchingDriver button {
  margin-top: 10px;
}
.btn-acompanhar {
  display: flex;
  justify-content: space-between;
}
.container-btn-searchingDriver {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-btn-searchingDriver button {
  margin-top: 10px;
}
.checked-perfil-SearchingDriver {
  display: flex;
  justify-content: center;
}

.container-btn-searchingDriver-qrCode {
  width: 200px;
  height: 200px;
}

.numero-pedido-SearchingDrive-id {
  text-align: right;
  font-size: 20px;
}

.perfilDriver-img {
  width: 79px;
  width: 79px;
  border-radius: 50%;
}

.searchingDriver-loader {
    /* z-index: 1000;
    margin-top: 15px;
    margin-right: 20px; */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    -webkit-animation:spin 6s linear infinite;
    -moz-animation:spin 6s linear infinite;
    animation:spin 6s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media only screen and (max-width: 800px) {
  .container-central-SearchingDriver {
    top: 10%;
    width: 90%;
    padding: 50px 30px;
    text-align: center;    
  }
}
