.column-LostPass  {
  width: 40%;
}
.container-AccountScreen{
  margin-left: 100px;
}

.container-principal-LostPass {
  width: 100vw;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.container-description{
  margin-bottom: 20px;
}
.container-inferior-LostPass {
  display: flex;
  width: 100%;
  height: 40vh;
  background: transparent linear-gradient(180deg, var(--color-1) 0%, var(--color-2) 100%) 0%;
  /* flex: auto; */
  align-items: center;
}

.container-inferior-LostPass button {
  /* margin-left: -20px; */
  align-content: center;
  
}

.container-form {
  height: 500px;
  justify-content: space-between;
  margin-left: 115px;
  display: flex;
  align-items: center;
  margin-right: 115px;

}

.column-detail-LostPass img{
  /* display: flex; */
  align-items: center;
  align-self: center;
  /* justify-content: center; */
  margin-right: 100px;
}



@media only screen and (max-width: 1000px) {
  .container-AccountScreen{
    /* margin-left: -43px; */
    display: flex;
    width: 120px;
    align-items: center;
    justify-self: center;
    align-self: center;
    justify-content: space-evenly;
  }

  .container-AccountScreen button {
    width: 320px;
    align-items: center;
    
  }

  .container-form {
    margin: 0px;
    height: auto;
    z-index: 1;
   
  }

  .container-inferior-LostPass button {
    margin-right: 0;
    /* margin-left: 20px; */
  }

  .column-LostPass  {
    width: 80%;
    margin-left: 10%;
    justify-content: space-evenly;
    margin-bottom: 70px;
    margin-top: 70px;
  }

  .container-AccountScreen form {
    margin-left: 50%;
   }

  @media only screen and (max-width: 600px) {
    .container {
      padding: 20px;
    }
    .column.detail-LostPass{
      display: none;
    }
    .container-form-LostPass{
      margin: 0px;
      height: auto;
      z-index: 1;
      margin-bottom: 70px;
      margin-top: 70px;
    }
    .container-form form {
      width: 100%;
    }
    .container .column {
      width: 100%;
    }
    .container form input {
      width: 100%;
    }
  
    
  }
  
}