.react-datetime-picker__inputGroup__input:invalid {
  background: none;
}
.react-datetime-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: none;
  border-radius: 4px;
}
.react-datetime-picker__calendar,
.react-datetime-picker__clock {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9999;
}

@media only screen and (max-width: 1200px) {
  .react-datetime-picker__wrapper div {
    margin-left: 0px;
  }
  .react-datetime-picker.react-datetime-picker--closed.react-datetime-picker--enabled {
    width: 0px;
  }
  .react-datetime-picker.react-datetime-picker--closed.react-datetime-picker--enabled {
    width: 0px;
    margin-left: -100px;
  }
  element.style {
    width: 314.2px;
  }
}

/* Fixes the bug line on mobile mobile devices on rainbow date time picker */
@media only screen and (max-width: 800px) {
  section {
    min-height: 380px;
  }
}