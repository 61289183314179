.container-principal-map {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.container-full-map-page{
  position: relative;
  width: 1280px;
  height: fit-content;
  max-width: 100%;
  margin: 0 auto;
}

.container-inferior-map {
  display: flex;
  justify-content: space-between;
  margin-top: 65px;
}
.margin-Map {
  margin: 35px;
}

.btn-voltar-map {
  margin-bottom: 30px;
  margin-left: -15px;
}
.itens-container-central-map {
  z-index: 1;
  margin: 35px;
  align-items: center;
}
.input-map {
  margin-bottom: 300px;
}
.container-inferior-cinza-map {
  display: flex;
  width: 100%;
  height: 215px;
  max-height: 100%;
  background: transparent linear-gradient(180deg, #c9c9c9 0%, #e9e9e9 100%) 0%
    0% no-repeat padding-box;
  flex: auto;
  text-align: center;
  position: relative;
}

@media only screen and (max-width: 1000px) {
  .container-inferior-map {
    flex-direction: column;
    align-items: center;
  }
  /* Solves the  footer white area bug on mobile */
  .container-inferior-cinza-map {
    height: auto;
  }
  .container-full-map-page {
    padding-left: 20px;
    padding-right: 20px;
  }

  .transpSegurado-map {
    margin-bottom: 15px;
  }
  .input-map {
    margin-bottom: 0px;
  }
  .btn-voltar-map {
    display: flex;
  }
}
