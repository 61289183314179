/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  display: flex;
  transition-duration: 0.2s;
  width: 100%;
  max-width: 380px;
  height: 64px;

  background: var(--unnamed-color-303030) 0% 0% no-repeat padding-box;
  background: #ff5e00 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  text-align: left;
  color: #ffffff;
  opacity: 1;
  font-size: 20px;
  justify-content: space-between;
  align-self: center;
  padding: 20px 25px 10px 30px;
  cursor: pointer;
  border: none;
  text-decoration: none;
  min-width: 510px;
}

.accordion img {
  /* margin-left: 5px; */
  margin-left: 30px;
}
.accordion p {
  /* margin-left: 5px; */
  margin-left: 100px;
}
/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {
  border: 1px solid #e3e3e3;
}

/* Style the accordion content title */
/* .accordion__title {
  font-weight: 600;
  font-size: 14px;
  text-align: left;
}
 */
/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  margin-top: 20px;
  background-color: white;
  overflow: auto;
  transition: max-height 0.6s ease;

  height: 100%;
  max-height: 600px;
}

/* Style the accordion content text */
.accordion__text {
  margin-top: 20px;

  /* font-family: "Open Sans", sans-serif; */
  font-weight: 400;
  font-size: 10px;
  padding: 18px;
}


@media only screen and (max-width: 600px) {
  .btn-trackingMap .accordion{
    min-width: 100%;
  }

  .accordion img {
    /* margin-left: 5px; */
    margin-left: 20px;
  }
  .accordion p {
    /* margin-left: 5px; */
    margin-left: 0px;
  }
}