.App {
  display: flex;
  font-family: sans-serif;
  border-radius: 10px;
  /* padding: 20px; */
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */

  min-height: auto;
}

/* .containerComponentTracking {
  width: 17%;
} */

.form-container {
  width: 50%;
}

.steps-container {
  display: grid;
  row-gap: 40px;
  grid-template-columns: 1fr;
}

.steps-item {
  font-size: 15px;
  min-height: 71px;
  margin-left: 17px;
  height: 30px;
  margin-top: 7px;
  margin-bottom: 10px;
}

.steps-item h3 {
  margin: 0;
  color: color;
}
.steps-item-date {
  font-size: 13px;
}

.vertical-slider {
  height: 100%;
  width: 25%;
  /* border: 1px solid grey; */
}


@media only screen and (max-width: 600px) {

  .steps-item {
    /* margin-left: 50px; */
    /* margin-top: 20px; */
  }
}