.toggle-container {
  width: 40px;
  background-color: #dcdcdc;
  cursor: pointer;
  user-select: none;
  border-radius: 100px;
  border: 1px solid #e0e0e0;
  padding: 1.5px;
  height: 22px;
  position: relative;
  display: flex;
  align-items: center;   
}
.dot {
  cursor: pointer;
  padding: 8px 8px;
  border-radius: 18px;
  min-width: 46px;
  justify-content: center;
  min-width: unset;
  border-radius: 100px;
  box-sizing: border-box;
  position: absolute;
  left: 2px;
  transition: all 0.3s ease;
  margin: 0px;
}
.toggle-container.on{
  background-color: #fff;
}
.toggle-container.off{
  background-color: #fff;
}
.toggle-container.on .dot{
  left:20px;
  background-color: #ff5e00;
  color: #ff5e00;  
}
.toggle-container.off .dot{
  left:3px;
  background-color: #c0c0c0;
  color: #c0c0c0;  
}

@media only screen and (max-width: 800px) {
  .toggle-container {
    height: 21px;
  }
}