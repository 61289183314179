.container-principal {
  justify-content: center;
}
.container-central-notfound{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}