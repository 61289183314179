.btnGoogle {
  font-size: 26px;
  width: 344px;
  height: 64px;

  transition-duration: 0.2s;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;

  border-radius: 3px;
  color: #5a5a5a;
  padding: 10px 30px;
  cursor: pointer;
}

.btnGoogle:hover {
  border: solid #e3e3e3;
}

.google {
  margin-right: 10px;
}
