.container-principal-Main{ 
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.container-main-central {
  width: 100%;
  padding-bottom: 50px;
}

.search-input-main-container {
  display: initial;
  width: 360px;
}

.box {
  background-color: #fff;
  margin-bottom: 12px;
  max-width: 100%;
  text-decoration: none; 
  align-items: center;
  color: var(--color-black-2);
  width: 360px;
  height: auto;
  min-height: 90px;
  border: 1px solid #00000033;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  box-shadow: 0px 3px 6px #00000029;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;  
}
  .box:hover{
    text-decoration: none;
    color: var(--color-black-2);
  }
  .box-local {
    padding: 6px;
    color: var(--color-1);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;      
  }  
  .box-local:hover{
    color: var(--color-black-2);
  }
  .box textarea {
    width: 200px;
    height: 70px;
    font-size: 13px;
    resize: none;
    margin-top: 4px;
    border: none;
    cursor: pointer;
    color: var(--color-1);
    text-align: left;

    overflow: hidden;
    background: transparent;
  }
  .box input {
    height: 70px;
    font-size: 20px;
    background: transparent;
    border: none;
    text-align: center;
    cursor: pointer;
    color: var(--color-1);
    margin: -7px;
  }
.checked {
  height: 30px;
  align-self: center;
}
.placeholder, 
::placeholder {
  font-size: 23px;
  color: #c0c0c0;
}
.placeholder{
  width: 30px;
  padding-left: 10px;
}

.informacao-central {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
}
.informacao-central h2 {
  margin-bottom: 15px;
}

.img-input {
  width: 41px;
  height: 35px;
}
.img-box-main {
  margin-top: 12px;
  height: 35px;
}
.icon-caixa {
  display: flex;
  margin-bottom: 10px;
  margin-top: 20px;
}
.icon-caixa h2 {
  padding-top: 12px;
  margin-left: 20px;
}
.icon-caixa h2,
.informacao-central h2{
  font-size: 22px;
}

.imgTransp-main.img {
  margin-top: 25px;
  padding: 100px;
}
.btn-finalizar h5 {
  margin-right: 15px;
}
.posicionamento-main {
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
}
.react-datetime-picker__inputGroup {
  min-width: calc(4px + (4px * 3) + 0.54em * 6 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 75px;
}
.react-datetime-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.container-main {
  /* margin: 15px; */
  display: flex;
  flex-direction: column;
}
.btnPadrao .imgBtnPadrao{
  align-self: auto;
}
.react-datetime-picker.react-datetime-picker--closed.react-datetime-picker--enabled {
  position: absolute;
  top: 0;
  margin-top: 35px;
  width: 100%;
  height: 77px;
  border: 0px solid transparent;
}
.react-datetime-picker__button:enabled {
  cursor: pointer;
  display: none;
}

.react-datetime-picker__inputGroup input,
.react-datetime-picker__inputGroup span {
  font-size: 20px;
  color: var(--color-1);
  border: 0;
  cursor: pointer;
}

.react-datetime-picker__wrapper div {
  margin-left: 80px;
}

.clear-icon {
  display: none;
}
.search-icon {
  order: 2;
  padding-right: 10px;
  width: 50px;
  height: 50px;
}
/* Shopcart : Produtcs */
.container-principal-Main .cx-info-transp-central {
  width: 100%;
  max-height: 280px;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1d1;
  padding: 35px 30px;
  border-radius: 5px;
  cursor: pointer;
  flex-direction: column;
  overflow: auto;
  margin-top:10px;
}
.cx-info-transp-central ul{
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.cx-info-transp-central ul li{
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e4e4e4;
}
.cx-info-transp-central ul li:last-child {
  border: 0;
  margin: 0;
}
.lista-item-OrderResume-input-name {
  width: 100%;
}

.cx-info-transp-central img.btShopCartRemove {
  opacity: 0.5;  
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;   
}
.cx-info-transp-central img.btShopCartRemove:hover {
  opacity: 1;  
}

.react-datepicker-wrapper input[type="text"]{
  border: 0;
  box-shadow: none;
  padding: 0;
  background: transparent;  
}
.button-chat{
  margin-top: 30px;
  margin-bottom: 10px;
  text-align: center;
}

/* Input de pesquisa de produtos */
.pesquisar-produtos{
  flex-direction: column;
  z-index: 1000;
  position: relative;
}
.pesquisar-produtos-autocomplete input:first-child{
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;    
}
.pesquisar-produtos-autocomplete{
  border-radius: 4px;
  height: 70px;
  max-height: 100%;
  font-size: 22px;
}
.informacao-central-select {
  /* display: flex; */
  justify-content: space-between;
  margin-top: 18px;
}


@media only screen and (max-width: 1280px) {
  .informacao-central-select {
    /* display: block; */
    justify-content: space-between;
    margin-top: 18px;
    align-items: center;
  }

  .informacao-central-select input{
    width: 100%;
  }
  
  .search-input-main-container{
    display: flex;
    flex-direction: column;
    background-color: transparent;
    margin-bottom: 12px;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: auto;
    height: auto;
    min-height: 90px;
    min-width: 100%;

  }
  .pesquisar-produtos-autocomplete-container {
    max-height: 300px;
    width: 100%;
  }
  /* .container-principal {
      height: auto;
  }
   */
  .informacao-central {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .btn-voltar {
    margin-left: 15px;
  }
  .posicionamento-main {
    align-items: center;
    flex-direction: column;
    margin-top:30px;
    margin-bottom: 30px;
  }
  .imgTransp-main {
    margin-top: 8px;
  }
  .rdt{
    width:200px;
  }
  .btn-ajuda {
    margin-bottom: 13px;
  }
  .container-main-central {
    height: auto;
    margin-bottom: 30px;
  }
  .campo-busca-main-central {
    width: 360px;
    /* max-width: 100%; */
    /* margin: 0 auto; */
    /* overflow: auto; */
  }
  .icon-caixa {
    justify-content: center;
  }

  .container-inferior-cinza {
    height: auto;
    margin-top: 50px;
  }
  /* Shopcart : Produtcs */
  .container-principal-Main .cx-info-transp-central {
    margin-top: 30px;
  }
  .container-principal-Main .cx-info-transp-central li {
    font-size: 14px;
    overflow: hidden;
  }
  .container-principal-Main .cx-info-transp-central input {
    font-size: 15px;
  }
  .cx-info-transp-central-div-span {
    font-size: 15px;
  }  
  .container-main-central .imgVoltar {
    margin: 30px 0px 0px 30px;
  }
  .react-datepicker-wrapper input[type="text"]{
    border: 0;
    box-shadow: none;
    padding: 0;
    background: transparent;
  }
  .react-datepicker-wrapper ::placeholder{
    font-size: 22px!important;
  }

  #modal-content-52, #modal-content-5{
    margin-top: 100px;
  }

  .btn-finalizar {
      margin-top: 30px;
  }  
}




/* Package */
.packages {
  margin-top: 25px;
}
.packages ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.packages li {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #d0d0d0;
  box-shadow: 0px 0px 10px #0000000d;
  height: 500px;
  margin-right: 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;   
  cursor:pointer;
}
.packages li:last-child {
  margin-right: 0;
}
.packages li:hover{
  border: 1px solid #c0c0c0;
  box-shadow: 0px 0px 20px #0000002a;
}
.packages li h2 {
  text-align: center;
  font-size: 20px;
  color: var(--color-1);
  margin-bottom: 20px;
}
.packages li p {
  text-align: justify;
  margin-bottom: 10px;
  white-space: break-spaces;
  height: 100%;
  width: 100%;
}
.packages li button {
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;  
}
.packages li button div {
  width: 100%;
  text-align: center;
}
.packages li button input[type="checkbox"] {
  width: 18px;
  height: 18px;
}

@media only screen and (max-width: 1280px) {
  .packages ul{
    flex-direction: column;
  }
  .packages li {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
}