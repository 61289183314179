.backgroundModal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  display: flex;
  flex-direction: column;
}

.title {
  width: 284px;
  height: 440px;
  background-color: #fff;
  border-radius: 4px;
  margin-top: 35px;
}
.btn-Modal {
  margin-top: 35px;
}
.perfilModal {
  display: flex;
  justify-content: center;
}

.conteudoModal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.conteudoModal h2 {
  /* font: italic normal 600 20px Segoe TV; */
  color: #ff5e00;
  margin-top: 20px;
  margin-bottom: 10px;
}
.conteudoModal h3 {
  color: var(--unnamed-color-303030);
  /*  font: italic normal 600 15px/21px Segoe TV; */
  color: #303030;
}

.conteudoModal h4 {
  color: #838383;
}
.conteudoModal img {
  margin-top: 35px;
  margin-bottom: 10px;
}
