.accordion__section_Help {
  display: flex;
  flex-direction: column;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion_Help {
  background-color: transparent;
  /* color: #5555; */
  cursor: pointer;

  display: flex;
  align-items: center;
  border: none;
  outline: none;
  /*  transition: background-color 0.6s ease; */
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion_Help:hover,
.active_Help {
  /*  background-color: #ccc; */
}

/* Style the accordion content title */
.accordion__title_Help {
  text-align: left;
  /* font: italic normal 600 16px/21px Segoe TV; */
  letter-spacing: 0px;
  color: #4b4b4b;
  margin-top: 20px;
}

/* Style the accordion chevron icon */
.accordion__icon_Help {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content_Help {
  background-color: white;
  overflow: auto;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text_Help {
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}
