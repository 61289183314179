.container-inferior-AccountScreen {
  width: 100%;
  height: 215px;
  background: transparent linear-gradient(180deg, var(--color-1) 0%, var(--color-2) 100%) 0%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.container-inferior-AccountScreen button {
  margin-right: 115px;
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 20px;
  }  
  .container-form {
    margin: 0px;
    height: auto;
    z-index: 1;
    margin-bottom: 50px;
  }
  .container-form form {
    width: 100%;
  }
  .container .column {
    width: 100%;
  }
  .container form input {
    width: 100%;
  }
  .container-inferior button {
    float: none;
    /* margin-top: 0; */
    /* margin-right: 0; */
  }
  
  .container-inferior form {
    margin-left: 30px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  /* margin-top: 50px; */
  }

  .container-AccountScreen {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .container-backButton {
    margin-bottom: 40px;
  }

  .container-backButton .imgVoltar{
    margin-left: 0;
  }
  .container-inferior-AccountScreen {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    min-height: 215px;
    padding: 20px;
  }

  .container-inferior-AccountScreen button {
    /* prevents the button to go foward like: desktop view */
    margin: 0px;
    display: flex;
    justify-content: space-between;
  }
}