.btnHome {
  text-align: left;
  display: flex;
  margin-bottom: 8px;
  border: none;
}
.btnHome span {
  align-self: center;
}
.btn {
  transition-duration: 0.2s;
  background: #3b5999 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
  opacity: 1;
  cursor: pointer;
  font-size: 26px;
  width: 344px;
  height: 64px;
  color: #ffffff;
  text-align: left;
  padding: 10px 30px;
}

.btn:hover {
  border: 1px solid #e3e3e3;
}
.facebook {
  margin-right: 10px;
}
