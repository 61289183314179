.container-LegalInfoPage {
  margin: 15px;
}

.container-LegalInfoPage h1 {
  text-align: left;
  /*  font: italic normal 600 26px/35px Segoe TV; */
  letter-spacing: 0px;
  color: #ff5e00;
}

.container-LegalInfoPage p {
  text-align: justify;
  /* font: normal normal normal 14px/19px Segoe TV; */
  font-size: 14px;
  letter-spacing: 0px;
  color: #707070;

  margin-top: 20px;
  text-justify: center;
}
