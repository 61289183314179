@import url("http://fonts.cdnfonts.com/css/segoe-ui-4?styles=18005");

.btnApple {
  transition-duration: 0.2s;
  background: #000000 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
  opacity: 1;
  cursor: pointer;
  font-size: 26px;
  width: 344px;
  height: 64px;
  color: #ffffff;
  padding: 10px 30px;
}

.btnApple:hover {
  border: 1px solid #e3e3e3;
}

.apple {
  margin-right: 10px;
}
.imgBtn {
  width: 75px;
  align-self: center;
  margin-top: 7px;
}
