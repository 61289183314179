.container-HelpPage {
  margin: 15px;
}
.btn-voltar-helpPage {
  margin: 15px;
}

.container-HelpPage h1 {
  text-align: left;
  /* font: italic normal 600 26px/35px Segoe TV; */
  letter-spacing: 0px;
  color: #ff5e00;
  margin-left: 15px;
}

.container-superior-HelpPage {
  display: flex;
  justify-content: space-between;
}

.container-HelpPage h2 {
  text-align: left;
  /*  font: italic normal 600 16px/21px Segoe TV; */
  letter-spacing: 0px;
  color: #ff6811;
  margin-top: 65px;
}

.container-HelpPage p {
  text-align: left;
  /* font: normal normal normal 14px/19px Segoe TV; */
  letter-spacing: 0px;
  color: #707070;
  margin-top: 15px;
  text-align: justify;
}

.container-HelpPage h3 {
  text-align: left;
  /*  font: italic normal 600 16px/21px Segoe TV; */
  font-size: 16px;
  letter-spacing: 0px;
  color: #4b4b4b;
  margin-top: 20px;
}

.btn-ajuda-HelpPage {
  display: flex;
  width: 403px;
  height: 52px;
  background: #303030;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  color: #ffffff;
  padding: 15px;
  cursor: pointer;
  /* justify-content: space-between; */
  align-items: flex-end;
}
.btn-ajuda-HelpPage p {
  text-align: left;
  font-size: 19px;
  color: #ffffff;
  margin-left: 60px;
}
@media only screen and (max-width: 800px) {
  .container-superior-HelpPage {
    display: flex;
    flex-direction: column;
  }

  .container-superior-HelpPage h1 {
    margin-bottom: 15px;
  }

  .btn-ajuda-HelpPage {
    display: flex;
    width: 100%;
  }
  .btn-voltar-helpPage {
    margin-left: 15px;
  }
}
