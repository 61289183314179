.backgroundModal-PaymentModal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  display: flex;
  flex-direction: column;
}

.title-PaymentModal {
  width: 500px;
  height: 250px;
  background-color: #fff;
  border-radius: 4px;
  /* margin-top: 60px; */
  padding: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 170px;
}

.title-PaymentModal span {
  font-weight: bold;
  cursor: pointer;
}
.btn-Modal {
  /* margin-top: 35px; */
}
.perfilModal {
  display: flex;
  justify-content: center;
}

.conteudoModal {
  display: flex;
  flex-direction: column;
  align-items: center;
 
}

.conteudoModal h2 {
  /* font: italic normal 600 20px Segoe TV; */
  color: #ff5e00;
  /* margin-top: 20px; */
  /* margin-bottom: 10px; */
}
.conteudoModal h3 {
  color: var(--unnamed-color-303030);
  /*  font: italic normal 600 15px/21px Segoe TV; */
  color: #303030;
  text-align: center;
}

.buttonModal {
  margin-top: 15px;
  color: white;
  background-color: #fc9b62;
  padding: 10px;
  border-radius: 5px;
}

.buttonModal:hover {
  background-color: #ff5e00;

}

.conteudoModal h4 {
  color: #838383;
}

