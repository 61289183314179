.container-inferior-cinza {
  display: flex;
  width: 100%;
  height: 215px;
  max-height: 100%;
  background: transparent linear-gradient(180deg, #c9c9c9 0%, #e9e9e9 100%) 0%
    0% no-repeat padding-box;
  flex: auto;
  text-align: center;
  padding-bottom: 40px;
}
.div {
  position: relative;
}
.container-superior {
  width: 100%;
  height: 55vh;
  padding: 45px;
  padding-top: 14%;
}
.cabecalho-home {
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.container-body {
  background: transparent linear-gradient(180deg, #f5812b 0%, #ff5e00 100%) 0%
    0% no-repeat padding-box;
  border-radius: 450px 0px 0px 0px;
  display: flex;
  height: 45vh;
  flex: auto;
}
.container-conteudo {
  position: relative;
  width: 1280px;
  margin: 0 auto;
  padding: 40px 0px 10px 0px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: 18px;
}

.container-conteudo h4 {
  margin-bottom: 25px;
}
.switch {
  display: flex;
  justify-content: space-between;
  width: 330px;
  padding: 5px 50px 10px 0px;
}
.switch p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
}

.link {
  text-decoration: none;
}
.posicionamento {
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
}
.imgBox {
  margin-top: 80px;
}

.login input {
  width: 289px;
  height: 54px;
  font-size: 21px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 8px;
  border-radius: 4px;
  border: none;
}
.logo-home {
  text-align: left;
  color: #ff5e00;
}
.app-beta {
  position: absolute;
  margin-top: -100px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.slogan{
  font-size: 16px;
  text-align: left;
  font-style: italic;
  margin-top: 20px;
}
.esqueci-senha {
  margin-bottom: 20px;
  font-size: 14px;
  text-align: left;
}
.esqueci-senha a{
  color: #fff;
  text-decoration: underline;
}
.lista-item-OrderResume-observations span {
  margin-left: 36px;
}
.mobile-arrow-down {
  display: none;
  position: absolute;
  transform: rotate(-90deg);
  top: 350px;
  animation: pulse-black 2s infinite;
}
.mobile-arrow-down .arrow {
  width: 50px;
}
.container-conteudo h4.home-cad-motorista{
  margin-top: 20px;
  margin-bottom: 10px;
  color: var(--color-white);
}
@keyframes pulse-black {
  0% {
    opacity: 0.5;
    margin-top: -20px;
  }
  
  70% {
    opacity: 1;
    margin-top: 0px;
  }
  
  100% {
    opacity: 0.5;
    margin-top: -20px;
  }
}

@media only screen and (max-width: 800px) {
  
  .mobile-arrow-down {
    display: block;
  }

  .app-beta{
    margin-top: -80px;
  }

  .container-principal-Home {
    margin-top: 0px;
    width: 100%;
    /* height: fit-content; */
    overflow-x: hidden;
  }
  .container-superior{
    height: auto;
  }
  .cabecalho-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
  }
  .selo-transporte-segurado{
    margin-top: 30px;
  }
  .lista-item-OrderResume-observations span {
    margin-left: 5px;
  }

  
  .container-conteudo {
    
    position: relative;
    width: 100%;
    margin: 60, auto;
    padding: 60px 140px 100px 150px;
    color: #fff;
    font-size: 18px;
    flex-direction: column;
    align-items: center;
  }
  .container-body {
    display: flex;
    flex: auto;
    height: fit-content;
  }
  .login h4 {
    margin-top: 20px;
  }
  .logo-home-img {
      width: 100%;
  }
  .login {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  /* .logo-home {
    margin-top: 80px; 
    padding: 20px;
  } */
  .slogan{
    text-align: center;
    font-size:20px;
    margin: 30px 0 15px 0;
  }
  .switch {
      margin-left: 41px;
  }  
  .esqueci-senha{
    font-size:20px;
    margin: 20px 0px;
  }

  .container-inferior-cinza {
    height: auto;
  }
}
