.inputForm input {
  max-width: 100%;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  margin-bottom: 13px;
  padding: 10px 15px;
  font-size: 25px;
}


.inputForm-error input {
  max-width: 100%;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 4px solid #EDADAD;
  border-radius: 5px;
  margin-bottom: 13px;
  padding: 10px 15px;
  font-size: 25px;
}
