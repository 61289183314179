.container-UserData {
  margin: 15px;
}
.container-UserData-inferior {
  display: flex;
  justify-content: flex-end;
  margin-top: 75px;
}
.container-form-UserData {
  display: flex;
  justify-content: center;
}
.formulario-userData {
  margin-top: 60px;
  width: 618px;
}
.form-userData {
  /* font: italic normal 18px/24px Segoe TV; */
  letter-spacing: 0px;
  color: #303030;
}
.posicao-img-user {
  margin: 48px 300px 40px 0px;
}
.imgVoltar-UserData h1 {
  margin: 6px 0px 0px 15px;
  color: #ff5e00;
}
/* .imgVoltar-UserData {
  margin-top: 35px;
} */

.button-upload-userData {
  display: flex;
  align-items: center;
  align-self: center;
  align-self: center;
}

/* .button-upload-image-userData {
  width: "40px";
  margin-right: "10px";
  margin-top: "10px";
} */
.profile-image-user-data {
  display: inline-block;
  width: 300px;
  height: 300px;
  border-radius: 50%;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@media only screen and (max-width: 1200px) {
  .posicao-img-user {
    margin: 60px 0px 40px 0px;
  }
  .container-form-UserData {
    flex-direction: column;
    max-width: 100%;
    align-items: center;

  }
  
  .formulario-userData {
    margin-top: 20px;
    width: 100%;
  }
  .container-UserData-inferior {
    flex-direction: column;
    align-items: center;
  }
}
