.container-navbar {
  display: flex;
  flex-direction: column;
  z-index: 9999;
}
.components-nav {
  width: 100%;
  height: 80px;
  background: transparent linear-gradient(180deg, #f5812b 0%, #ff5e00 100%) 0%
    0% no-repeat padding-box;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0px 30px 0px 30px;
  align-items: center;
}
.img-navBar {
  cursor: pointer;
}
.components-nav-bts {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.components-nav-internet {
    padding: 0px;
    color: #fff;
}
.components-nav-internet img {
  width: 35px;
  height: auto;
}
.internet-ok {
  width: 120px;
  display: flex;
  margin-right: 5px;
  justify-content: space-between;
  align-items: center;
  display: none;
}
.internet-error {
  width: 120px;
  display: flex;
  margin-right: 5px;
  justify-content: space-between;
  align-items: center;
}
.internet-error .icon-loading {
  width: 25px;
  height: 25px;
  border-radius: 100px;
  border: 3px dotted #fff;
  animation: spin 6s linear infinite;
  margin: 0px 83px;
  position: absolute;
}
.internet-error span {
  width: 80px;
  font-size: 11px;
  line-height: 12px;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }    