.container-Tracking {
  margin: 15px;
}

.container-Tracking h1 {
  text-align: left;
  /* font: italic normal 600 21px/28px Segoe TV; */
  letter-spacing: 0px;
  color: #ff5e00;
  margin-bottom: 60px;
}

.tracking-transporte {
  max-width: 1164px;
  height: 200px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 5px;

  margin-bottom: 20px;
}

.posicionamentoinfoTracking {
  display: flex;
  justify-content: space-between;
}

.dadosTracking {
  display: flex;
  flex-direction: column;
}
.dadosTracking h1 {
  margin-bottom: 1px;
  font-size: 18px;
  margin: 10px 0;
}

.dadosTracking h2 {
  text-align: left;
  letter-spacing: 0px;
  color: #424242;
  font-size: 12px;
  margin-bottom: 10px;
}
/* .perfilTracking img {
  height: 50px;
 
} */



.perfilTracking-img {
  border-radius: 100%;
  height: 110px;
  width: 110px;
  object-fit: cover;
  /* object-position: center; */
}

.dadosTracking div {
  text-align: left;
  /* font: italic normal 600 14px/19px Segoe TV; */
  font-size: 14px;
  color: #424242;
  opacity: 0.5;
  border: none;
}
.infoTracking {
  margin: 5px 0px 0px 15px;
}

.perfilTracking {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: space-between;
  text-align: center;
  margin-right: 20px;
  height: auto;
  max-height: 150px;
}

.nota-tracking {
  display: flex;
  width: 44px;
  height: 25px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  padding: 5px;
  justify-content: space-between;
  top: 4px;
}

.nota-tracking input {
  border: none;
  text-align: right;
  /*  font: italic normal 600 12px/16px Segoe TV; */
  font-size: 12px;
  letter-spacing: 0px;
  color: #505050;
  width: 20px;
}

.avaliacaoTracking {
  /*  top: -17px; */
  margin: -10px;
  display: flex;
}

.avaliacaoTracking {
  width: 46px;
  height: 320px;
  margin-top: -5px;
  margin-left: 3px;
}

.dadosTracking img {
  width: 100px;
  height: 19px;
}

.estrela {
  height: 15px;
  width: 20px;
  margin-right: 10px;
}

.coletaTracking-img {
  width: 1    09px;
  height: 50px;
  margin-bottom: 25px;
}
.coletaTracking-status {
  padding: 20px 0px;
}
.super-star-tracking img {
  height: 40px;
  margin-right: 10px;
}

.tracking-loader {
  width: 100%;
  display: flex;
  justify-content: center;
}

.tracking-loader img {
  position: absolute;
  z-index: 1000;
  margin-top: 15px;
  margin-right: 20px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  -webkit-animation:spin 6s linear infinite;
  -moz-animation:spin 6s linear infinite;
  animation:spin 6s linear infinite;

}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@media only screen and (max-width: 1280px) { 
  .perfilTracking {
    /* margin-left: 20%; */
  }


  .perfilTracking-img  {
    border-radius: 100%;
    height: 85px;
    width: 85px;
    object-fit: cover;
  }
  .tracking-transporte {
    height: auto;
    padding-bottom: 20px;
  }
  
  .tracking-loader img{
    position: initial;
    margin: 0;
  }
  

  .posicionamentoinfoTracking {
    
    flex-direction: column-reverse;
  }
}