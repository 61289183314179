/* Font */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

/* Configs */
:root {
  --color-1: #ff5e00;
  --color-2: #f5812b;
  --color-3: #303030;
  --color-text: #666;
  --color-white: #fff;
  --color-red: #f00;
  --color-gray-1: #f0f0f0;
  --color-gray-2: #c0c0c0;
  --color-gray-3: #75757509;
  --color-black-1: #333;
  --color-black-2: #000;
  --color-green-1: #6edc25;
  --font: "Noto Sans", sans-serif;
  --shadow-input: 0px 0px 5px rgba(0, 0, 0, 0.2);
  --shadow-input-hover: 0px 0px 2px rgba(0, 0, 0, 0.5);
  --radius: 4px;
}

body,
* {
  font-family: var(--font);
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}
body {
  font: 400 14px var(--font);
  background: #fff;
  -webkit-font-smoothing: antialiased;
}

/* Scrolls */
::-webkit-scrollbar{
	width: 12px;
	background-color: #F5F5F5;
	/* Transition */
	-webkit-transition: all 0.3s ease-in-out;	
	transition: all 0.3s ease-in-out;	
}
::-webkit-scrollbar-thumb {
	border-radius: 0px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}
::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 0px;
	background-color: #F5F5F5;
	cursor:default;
	/* Transition */
	-webkit-transition: all 0.3s ease-in-out;	
	transition: all 0.3s ease-in-out;		
}

h1 {
  color: #ff5e00;
  margin-bottom: 20px;
}
h2,h3,h4,h5 {
  font-weight: normal;
}
/* Page Structure */
.container {
  position: relative;
  width: 1280px;
  height: fit-content;
  max-width: 100%;
  margin: 0 auto;
}
.container-principal {
  width: 100vw;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.container-form {
  height: 500px;
  justify-content: space-between;
  margin-left: 115px;
  display: flex;
  align-items: center;
}
.container .container-description {
  color: var(--color-black-2);
  margin-bottom: 15px;
  font-size: 18px;
}
.container .container-form form {
  width: 100%;
  display: block;
}
.container-inferior {
  width: 100%;
  height: 215px;
  background: transparent linear-gradient(180deg, var(--color-1) 0%, var(--color-2) 100%) 0%;
  flex: auto;
}
.container-inferior button {
  float: right;
  margin-top: 70px;
  margin-right: 90px;
}
.container .column {
  width: 50%;
}
.container .column.detail {
  flex: 1;
  display: flex;
  justify-content: center;
}
.container .column.detail img {
  max-width: 50%;
  max-height: 50%;
}

/* Form globals */
::-webkit-input-placeholder {
  color: #c4c4c4;
}
input[type="button"],
input[type="reset"],
input[type="submit"],
button {
  cursor: pointer;
  border: none;  
  box-shadow: 0px 3px 6px #00000029;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover,
button:hover {
  box-shadow: 0px 1px 2px #00000029;
}
/* Inputs errors  */
.inputField span, 
.inputError {
  display: block;
  position: absolute;
  background: #000;
  color: #fff!important;
  font-size: 12px;
  padding: 5px 10px;
  margin: 50px 0px 0px 10px;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 999;
}


/* Toastify alert customization  */
.Toastify__toast {
  margin-top: 5px;
}
@media only screen and (max-width: 800px) {
  .Toastify__toast-container--top-center, 
  .Toastify__toast-container--top-left, 
  .Toastify__toast-container--top-right {
    top: 5%;
    width: 90%;
    margin: 5%;
  }    
}

@media only screen and (max-width: 800px) {
  .container-inferior button {
    /* float: right; */
    margin-top: 30px;
    margin-right: 0;
    margin-left: 20px;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 20px;
  }  
  .container-form {
    margin: 0px;
    height: auto;
    z-index: 1;
    margin-bottom: 50px;
  }

  
  .container-form form {
    width: 100%;
  }
  .container .column {
    width: 100%;
  }
  .container form input {
    width: 100%;
  }
  .container-inferior button {
      margin-left: -20px;
  }
  .container-inferior form {
    margin-left: 30px;
  }
}
