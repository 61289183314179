.Loading{
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    flex: 1 1;
    background: #fff;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 99999999999;
    overflow: hidden;
}
    .Loading .loadingWrapper{
        width: 50%;
        display: block;
        margin: 20% auto;
        color:var(--color-1);
        font-weight: bold;
        font-style: italic;        
    } 
    .loadingIcon img {
        width: 50px;
        height: 50px;
        -webkit-animation:spin 6s linear infinite;
        -moz-animation:spin 6s linear infinite;
        animation:spin 6s linear infinite;
    }
    @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
    @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
    @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }    