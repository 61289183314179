/* Alert UI */
.MuiDialog-paperWidthSm {
    border-top: 5px solid var(--color-1);
}

/* Custom Alert */
/* Alert box */
.alert{
    border: 0;
    border-radius:5px;
    background: #fff;
    padding: 30px;
    display: flex;
    box-shadow: 0 0 10px #00000010;
    border-top:5px solid #f0f0f0;
}
/* Alert types */
alert.info{
    border-top:5px solid #ff9800;
}
alert.success{
    border-top:5px solid #4caf50;
}
alert.error{
    border-top:5px solid #fc2803;
}