.container-principal-payments {
  width: 100vw;
  /* height: 1230px; */

  height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.margin-payments {
  margin: 15px;
}

.container-central-payments {
  display: flex;
  justify-content: space-between;
}
.container-central-payments h1 {
  text-align: left;
  /* font: italic normal 600 26px/35px Segoe TV; */
  letter-spacing: 0px;
  color: #ff5e00;
}
.container-central-payments h3 {
  text-align: left;
  /* font: normal normal normal 14px/19px Segoe TV; */
  letter-spacing: 0px;
  color: #707070;
  margin-top: 20px;
}

.divisao-payments {
  border-left: 2px solid #707070;
  opacity: 0.3;
}

.cartao-payments {
  width: 336px;
  height: 182px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.cartao-payments div {
  width: 301px;
  height: 50px;
  background: #e1e1e1 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 0.42;
}

.input-date-cod-payments {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.input-date-cod-payments::placeholder {
  text-align: center;
}
.switch-payments {
  margin-top: 15px;
  margin-bottom: 30px;
  display: flex;
}
.switch-payments h5 {
  color: var(--unnamed-color-303030);
  text-align: left;
  /*   font: italic normal 600 13px/19px Segoe TV; */
  letter-spacing: 0px;
  color: #303030;
  margin-left: 10px;
}

.container-payments-right h3 {
  text-align: left;
  /* font: normal normal normal 14px/19px Segoe TV; */
  letter-spacing: 0px;
  color: #707070;
}
.container-payments-right h1 {
  text-align: left;
  /*   font: italic normal 600 26px/35px Segoe TV; */
  letter-spacing: 0px;
  color: #ff5e00;
}

.input-cartao-payments {
  width: 560px;
  min-width: 100px;
  height: 57px;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: self-end;
  color: #e4e4e4;
  padding: 15px;
}
.img-payments {
  margin-left: 10px;
  cursor: pointer;
}
.input-cartao-payments p {
  font-size: 60px;
  /*   letter-spacing: 15px; */
}
.min-cartao-payments {
  width: 82px;
  height: 45px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 4px;
  top: 10px;
}
.min-centro-cartao-payments {
  width: 74px;
  height: 12px;
  background: #e1e1e1 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 0.42;
}
.input-cartao-payments input {
  border: none;
  text-align: right;
  padding: 5px;
  color: #707070;
}
.payments {
  display: flex;
  margin-top: 15px;
  align-items: center;
}
/* .form-payments ::placeholder {
  text-align: center;
  font-size: 18px;
} */

.form-payments input {
  display: flex;
  width: 100%;
  height: 63px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  /* font-size: 18px; */
  text-align: center;
  box-shadow: 0px 3px 6px #00000029;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;  
}

.input-date-cod-payments-last-input {
  display: flex;
  width: 100%;
  height: 63px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  font-size: 25px;
  text-align: center;
  box-shadow: 0px 3px 6px #00000029;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;  
}
.input-date-cod-payments input::placeholder {
  margin: 0;
  text-align: center;
}


.btn-finalizar-payments{
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-finalizar-payments button {
  width: 300px;
}

@media only screen and (max-width: 1200px) {
  .container-central-payments {
    flex-direction: column;
    align-items: center;
  }
  .container-payments-left {
    max-width: 100%;
  }

  .container-central-payments h1 {
    text-align: center;
  }

  .container-payments-right h1 {
    margin-top: 15px;
    text-align: center;
  }

  .container-payments-right h3 {
    text-align: center;
  }
  .input-cartao-payments input {
    /* text-align: center; */
  }
/* 
  .form-payments input {
    display: column;
  } */

  .input-date-cod-payments input{
    width: 50%;
  }

}
@media only screen and (max-width: 500px) {
  .input-cartao-payments input {
    /*  text-align: inherit; */
  }

  .btn-finalizar-payments button{
    width: 300px;
  }
  .input-cartao-payments p {
    font-size: 30px;
    text-align: right;
  }
}
