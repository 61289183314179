@import url(http://fonts.cdnfonts.com/css/segoe-ui-4?styles=18005);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap);
.Loading{
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    flex: 1 1;
    background: #fff;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 99999999999;
    overflow: hidden;
}
    .Loading .loadingWrapper{
        width: 50%;
        display: block;
        margin: 20% auto;
        color:var(--color-1);
        font-weight: bold;
        font-style: italic;        
    } 
    .loadingIcon img {
        width: 50px;
        height: 50px;
        -webkit-animation:spin 6s linear infinite;
        animation:spin 6s linear infinite;
    }
    @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
    @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }    
.btnHome {
  text-align: left;
  display: flex;
  margin-bottom: 8px;
  border: none;
}
.btnHome span {
  align-self: center;
}
.btn {
  transition-duration: 0.2s;
  background: #3b5999 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
  opacity: 1;
  cursor: pointer;
  font-size: 26px;
  width: 344px;
  height: 64px;
  color: #ffffff;
  text-align: left;
  padding: 10px 30px;
}

.btn:hover {
  border: 1px solid #e3e3e3;
}
.facebook {
  margin-right: 10px;
}

.btnApple {
  transition-duration: 0.2s;
  background: #000000 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
  opacity: 1;
  cursor: pointer;
  font-size: 26px;
  width: 344px;
  height: 64px;
  color: #ffffff;
  padding: 10px 30px;
}

.btnApple:hover {
  border: 1px solid #e3e3e3;
}

.apple {
  margin-right: 10px;
}
.imgBtn {
  width: 75px;
  align-self: center;
  margin-top: 7px;
}

.btnGoogle {
  font-size: 26px;
  width: 344px;
  height: 64px;

  transition-duration: 0.2s;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;

  border-radius: 3px;
  color: #5a5a5a;
  padding: 10px 30px;
  cursor: pointer;
}

.btnGoogle:hover {
  border: solid #e3e3e3;
}

.google {
  margin-right: 10px;
}

.btnCriarConta {
  font-size: 26px;
  width: 330px;
  height: 60px;
  transition-duration: 0.2s;
  background: var(--color-white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  border-radius: 5px;
  color: #5a5a5a;
  padding: 10px 30px;
  cursor: pointer;
}
.btnCriarConta:hover {
  box-shadow: 0px 1px 2px #00000029;
}
.criarConta {
  margin-right: 10px;
}
@media only screen and (max-width: 800px) {
  .btnCriarConta {
    width: 290px;
  }
}
.btnSejaMotorista {
  font-size: 18px;
  width: 330px;
  height: 60px;
  transition-duration: 0.2s;
  background: var(--color-black-1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  border-radius: 5px;
  color: var(--color-white);
  padding: 10px 30px;
  cursor: pointer;
}
.btnSejaMotorista:hover {
  box-shadow: 0px 1px 2px #00000029;
}
.SejaMotorista {
  margin-right: 10px;
}
@media only screen and (max-width: 800px) {
  .btnSejaMotorista {
    width: 290px;
  }
}
.container-inferior-cinza {
  display: flex;
  width: 100%;
  height: 215px;
  max-height: 100%;
  background: transparent linear-gradient(180deg, #c9c9c9 0%, #e9e9e9 100%) 0%
    0% no-repeat padding-box;
  flex: auto;
  text-align: center;
  padding-bottom: 40px;
}
.div {
  position: relative;
}
.container-superior {
  width: 100%;
  height: 55vh;
  padding: 45px;
  padding-top: 14%;
}
.cabecalho-home {
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.container-body {
  background: transparent linear-gradient(180deg, #f5812b 0%, #ff5e00 100%) 0%
    0% no-repeat padding-box;
  border-radius: 450px 0px 0px 0px;
  display: flex;
  height: 45vh;
  flex: auto;
}
.container-conteudo {
  position: relative;
  width: 1280px;
  margin: 0 auto;
  padding: 40px 0px 10px 0px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: 18px;
}

.container-conteudo h4 {
  margin-bottom: 25px;
}
.switch {
  display: flex;
  justify-content: space-between;
  width: 330px;
  padding: 5px 50px 10px 0px;
}
.switch p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
}

.link {
  text-decoration: none;
}
.posicionamento {
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
}
.imgBox {
  margin-top: 80px;
}

.login input {
  width: 289px;
  height: 54px;
  font-size: 21px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 8px;
  border-radius: 4px;
  border: none;
}
.logo-home {
  text-align: left;
  color: #ff5e00;
}
.app-beta {
  position: absolute;
  margin-top: -100px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.slogan{
  font-size: 16px;
  text-align: left;
  font-style: italic;
  margin-top: 20px;
}
.esqueci-senha {
  margin-bottom: 20px;
  font-size: 14px;
  text-align: left;
}
.esqueci-senha a{
  color: #fff;
  text-decoration: underline;
}
.lista-item-OrderResume-observations span {
  margin-left: 36px;
}
.mobile-arrow-down {
  display: none;
  position: absolute;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  top: 350px;
  -webkit-animation: pulse-black 2s infinite;
          animation: pulse-black 2s infinite;
}
.mobile-arrow-down .arrow {
  width: 50px;
}
.container-conteudo h4.home-cad-motorista{
  margin-top: 20px;
  margin-bottom: 10px;
  color: var(--color-white);
}
@-webkit-keyframes pulse-black {
  0% {
    opacity: 0.5;
    margin-top: -20px;
  }
  
  70% {
    opacity: 1;
    margin-top: 0px;
  }
  
  100% {
    opacity: 0.5;
    margin-top: -20px;
  }
}
@keyframes pulse-black {
  0% {
    opacity: 0.5;
    margin-top: -20px;
  }
  
  70% {
    opacity: 1;
    margin-top: 0px;
  }
  
  100% {
    opacity: 0.5;
    margin-top: -20px;
  }
}

@media only screen and (max-width: 800px) {
  
  .mobile-arrow-down {
    display: block;
  }

  .app-beta{
    margin-top: -80px;
  }

  .container-principal-Home {
    margin-top: 0px;
    width: 100%;
    /* height: fit-content; */
    overflow-x: hidden;
  }
  .container-superior{
    height: auto;
  }
  .cabecalho-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
  }
  .selo-transporte-segurado{
    margin-top: 30px;
  }
  .lista-item-OrderResume-observations span {
    margin-left: 5px;
  }

  
  .container-conteudo {
    
    position: relative;
    width: 100%;
    margin: 60, auto;
    padding: 60px 140px 100px 150px;
    color: #fff;
    font-size: 18px;
    flex-direction: column;
    align-items: center;
  }
  .container-body {
    display: flex;
    flex: auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .login h4 {
    margin-top: 20px;
  }
  .logo-home-img {
      width: 100%;
  }
  .login {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  /* .logo-home {
    margin-top: 80px; 
    padding: 20px;
  } */
  .slogan{
    text-align: center;
    font-size:20px;
    margin: 30px 0 15px 0;
  }
  .switch {
      margin-left: 41px;
  }  
  .esqueci-senha{
    font-size:20px;
    margin: 20px 0px;
  }

  .container-inferior-cinza {
    height: auto;
  }
}

.btnAvancar {
  width: 284px;
  height: 69px;
}
.toggle-container-mobile {
  width: 40px;
  background-color: #dcdcdc;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 100px;
  border: 1px solid #e0e0e0;
  padding: 1.5px;
  height: 22px;
  position: relative;
  display: flex;
  align-items: center;   
}
.dot-mobile {
  cursor: pointer;
  padding: 8px 8px;
  border-radius: 18px;
  min-width: 46px;
  justify-content: center;
  min-width: unset;
  border-radius: 100px;
  box-sizing: border-box;
  position: absolute;
  left: 2px;
  transition: all 0.3s ease;
  margin: 0px;
}
.toggle-container-mobile.on{
  background-color: #fff;
}
.toggle-container-mobile.off{
  background-color: #fff;
}
.toggle-container-mobile.on .dot-mobile{
  left:20px;
  background-color: #ff5e00;
  color: #ff5e00;  
}
.toggle-container-mobile.off .dot-mobile{
  left:3px;
  background-color: #c0c0c0;
  color: #c0c0c0;  
}

@media only screen and (max-width: 800px) {
  .toggle-container-mobile {
    height: 30px;
    width: 70px;
  }

  .toggle-container-mobile.on .dot-mobile{
    left:40px;
    background-color: #ff5e00;
    color: #ff5e00;  
  }
  .dot-mobile {
    padding:12px 12px;
  }
}
.inputForm input {
  max-width: 100%;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  margin-bottom: 13px;
  padding: 10px 15px;
  font-size: 25px;
}


.inputForm-error input {
  max-width: 100%;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 4px solid #EDADAD;
  border-radius: 5px;
  margin-bottom: 13px;
  padding: 10px 15px;
  font-size: 25px;
}

.imgVoltar{
    background-color: transparent;
    box-shadow: none;
    position: relative;
    left:0;
    margin: 20px;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s ease-in-out;    
}
.imgVoltar:hover{
    box-shadow: none;
    left: -10px;
}

@media only screen and (max-width: 600px) {
    .imgVoltar {
        margin: 30px 0px 0px 20px;
    }
}
.inputMaskPhone input {
  max-width: 100%;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  margin-bottom: 13px;
  padding: 10px 15px;
  font-size: 25px;
}


.inputMaskPhone-error input {
  max-width: 100%;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 4px solid #EDADAD;
  border-radius: 5px;
  margin-bottom: 13px;
  padding: 10px 15px;
  font-size: 25px;
}

.container-inferior-AccountScreen {
  width: 100%;
  height: 215px;
  background: transparent linear-gradient(180deg, var(--color-1) 0%, var(--color-2) 100%) 0%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.container-inferior-AccountScreen button {
  margin-right: 115px;
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 20px;
  }  
  .container-form {
    margin: 0px;
    height: auto;
    z-index: 1;
    margin-bottom: 50px;
  }
  .container-form form {
    width: 100%;
  }
  .container .column {
    width: 100%;
  }
  .container form input {
    width: 100%;
  }
  .container-inferior button {
    float: none;
    /* margin-top: 0; */
    /* margin-right: 0; */
  }
  
  .container-inferior form {
    margin-left: 30px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  /* margin-top: 50px; */
  }

  .container-AccountScreen {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .container-backButton {
    margin-bottom: 40px;
  }

  .container-backButton .imgVoltar{
    margin-left: 0;
  }
  .container-inferior-AccountScreen {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    min-height: 215px;
    padding: 20px;
  }

  .container-inferior-AccountScreen button {
    /* prevents the button to go foward like: desktop view */
    margin: 0px;
    display: flex;
    justify-content: space-between;
  }
}
.container-DeliveryProfile-principal {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.container-DeliveryProfile-inferior {
  display: flex;
  width: 100%;
  height: 215px;
  background: transparent linear-gradient(180deg, #c9c9c9 0%, #e9e9e9 100%) 0%
    0% no-repeat padding-box;
  flex: auto;
}

.cx-tipo-carga {
  width: 365px;
  height: 146px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 35px 30px;
  cursor: pointer;
  z-index: 1;
}

.container-tipo-carga span {
  display: flex;
  align-items: center;
  align-self: center;
  margin: 0;
  cursor: pointer;
  color: var(--color-3);
  text-align: right;
  font-size:18px;
  align-content: flex-start;
  justify-content: space-between;  
}

.container-tipo-carga input {
  display: block;
  visibility: transparent;
  position: absolute;
}

.container-tipo-carga .cx-tipo-carga.on {
  border: 1px solid var(--color-1);
}

.container-tipo-carga {
  display: flex;
  /* width: 100%; */
  justify-content: space-between;
}
.container-central-DeliveryProfile {
  width: 100%;
  min-height: 500px;
}

.container-central-DeliveryProfile h4 {
  align-self: center;
  margin: 0;
}
.container-central-DeliveryProfile h1 {
  color: #ff5e00;
  /*  font: italic normal 28px/37px Segoe TV; */
  text-align: center;
  margin-bottom: 65px;
  margin-top: 100px;
}
.container-DeliveryProfile {
  margin: 15px;
}

@media only screen and (max-width: 800px) {
  .container-tipo-carga {
    flex-direction: column;
    align-items: center;
  }

  .cx-tipo-carga {
    max-width: 350px;
    width: 100%;
    height: 120px;
    margin-bottom: 10px;
  }

  .container-central-DeliveryProfile h1 {
    color: #ff5e00;
    font-size: 22px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 45px;
  }

  .posicionamento {
    margin-top: 25px;
    margin-bottom: 30px;
    flex-direction: column;
    align-items: center;
  }
  .img-transpSegurado-deliveryProfile {
    margin-bottom: 15px;
  }
}

.status {
  background-color: var(--color-gray-2);
  border-radius: 5px;
  padding: 5px;
  color: var(--color-white);
  padding: 5px 20px;
  box-shadow: 1px 1px 10px #0000004a;
  text-align: center;
  min-width: 150px;
}

/* Alert order status */
/* Novo */
.status-0{
  color: #bdf7ff;
  background-color: #095df0;
  border-color: #bedaf1;
}
/* Confirmado */
.status-1{
  color: #c4ffea;
  background-color: #29906b;
  border-color: #bff0de;
}
/* Em coleta */
.status-10{
  color: #cdc9ff;
  background-color: #4242ff;
  border-color: #b8c5f8;
}
/* Em entrega */
.status-20{
  color: #e5baff;
  background-color: #914ce0;
  border-color: #efb4fc;
}
/* Cancelado */
.status-30{
  color: #3b3b3b;
  background-color: #e3e3e3;
  border-color: #dbd5d5;
}
/* Adiado */
.status-40{
  color: #765901;
  background-color: #ffeec8;
  border-color: #ffe6b0;
}
/* Finalizado */
.status-50{
  color: #dbdbdb;
  background-color: #383838;
  border-color: #d7d7d7;
}
/* Pagamento negado / nao realizado */
.status-2001{
  color: #fff;
  background-color: #ff4141;
  border-color: #f40000;
}
.order-monitor {
  width: auto;
  height: auto;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  text-decoration: none;
}

.order-monitor__order {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 2em 2em;
  margin: 0em;
  background: #f4f4f4;
  border-left: 1px solid var(--color-3);
  cursor: pointer;
}

.order-monitor__order__header__title {
  color: var(--color-3);
}

@media only screen and (max-width: 800px) {
  .order-monitor__order {
    width: auto;
  }

  .order-monitor__order__header__title {
    margin-left: 10px;
    margin-right: 10px;
  }
}


/* Icon */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #ff5e00;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite;
}
@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}

.container-navbar {
  display: flex;
  flex-direction: column;
  z-index: 9999;
}
.components-nav {
  width: 100%;
  height: 80px;
  background: transparent linear-gradient(180deg, #f5812b 0%, #ff5e00 100%) 0%
    0% no-repeat padding-box;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0px 30px 0px 30px;
  align-items: center;
}
.img-navBar {
  cursor: pointer;
}
.components-nav-bts {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.components-nav-internet {
    padding: 0px;
    color: #fff;
}
.components-nav-internet img {
  width: 35px;
  height: auto;
}
.internet-ok {
  width: 120px;
  display: flex;
  margin-right: 5px;
  justify-content: space-between;
  align-items: center;
  display: none;
}
.internet-error {
  width: 120px;
  display: flex;
  margin-right: 5px;
  justify-content: space-between;
  align-items: center;
}
.internet-error .icon-loading {
  width: 25px;
  height: 25px;
  border-radius: 100px;
  border: 3px dotted #fff;
  -webkit-animation: spin 6s linear infinite;
          animation: spin 6s linear infinite;
  margin: 0px 83px;
  position: absolute;
}
.internet-error span {
  width: 80px;
  font-size: 11px;
  line-height: 12px;
}
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }    
.modalContainer {
  width: 375px;
  height: 100%;
  background: transparent linear-gradient(180deg, var(--color-1) 0%, var(--color-2) 100%) 0%
    0% no-repeat padding-box;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  right: 0px;
  top: 0px;
  position: fixed;
  transition: all 0.3s ease-in-out;  
  /** Some layouts can break and you can't logout, overflow fix it*/
  overflow: hidden;
  overflow-x: hidden;
  /** In order to keep the bar always in the top view, preventing bug in map pages  */
  z-index: 9999;

}
.modalContainer.off {
  right: -400px;
}
.modalContainer.on {
  right: 0px;
}
.titleCloseBtn {
  position: relative;
  left: 85%;  
  display: flex;
  margin-top: 15px;
  transition: all 0.3s ease-in-out;    
  cursor: pointer;
  -webkit-transform: scale(1.0);
          transform: scale(1.0);
}
.titleCloseBtn:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  left: 90%; 
}
.perfil-menu-modal {
  text-align: center;
}

.profile-image-menu-modal {
  display: inline-block;
  width: 250px;
  height: 250px;
  border-radius: 50%;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.dados-menu-modal {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -3px 6px #00000029;
  border-radius: 10px 10px 0px 0px;

  top: 307px;
  padding: 15px;
  position: absolute;
  width: 375px;
  height: 100%;
}
.border-info-modal {
  width: 340px;
  border-top: 1px solid #00000017;
  border-bottom: 1px solid #00000017;
  padding: 25px 10px 10px 10px;
  padding-bottom: 20px;
}
.info-menu-modal a {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: var(--color-3);
  font-weight: bold;
  margin-top: 4px;
  display: block;
  text-decoration: none;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;    
}
.info-menu-modal a:hover {
  color: var(--color-1);
  text-decoration: none;
  opacity: 1;
}
.dados-menu-modal h2 {
  margin-top: 43px;
  margin-bottom: 20px;
  text-align: center;
  letter-spacing: 0px;
  color: var(--color-1);
}
.version {
  margin-top: 5px;
  text-align: center;
  font-size: 12px;
  color: #303030;
  opacity: 0.4;
}

/* controls tour slider */
.action-tour-modal-menu {
  margin-top: 35px;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
}

.btnPadrao, 
.btnPadraoPreto,
.btnPadraoPeq, 
.btnPadraoPretoPeq {
  display: flex;
  width: 284px;
  height: 69px;
  background: var(--color-1) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: left;
  color: #ffffff;
  opacity: 1;
  font-size: 25px;
  justify-content: space-between;
  align-self: center;
  padding: 16px 25px 10px 25px;
  cursor: pointer;
  border: none; 
}
.btnPadraoPreto, .btnPadraoPretoPeq{
  background: var(--color-black-1) 0% 0% no-repeat padding-box;
}
.btnPadrao:hover, .btnPadraoPeq:hover {
  background: var(--color-2) 0% 0% no-repeat padding-box;
}
.btnPadraoPreto:hover, .btnPadraoPretoPeq:hover{
  background: var(--color-black-2) 0% 0% no-repeat padding-box;
}
/* Button small */
.btnPadraoPeq, 
.btnPadraoPretoPeq
{
  width: 184px;
  height: 49px;
  padding: 10px 15px 5px 15px;
  font-size: 20px;
}

/* Button icon */
.btnPadrao .imgBtn, 
.btnPadrao .imgBtnPadrao, 
.btnPadraoPreto .imgBtn,
.btnPadraoPreto .imgBtnPadrao,
.btnPadraoPeq .imgBtn, 
.btnPadraoPeq .imgBtnPadrao, 
.btnPadraoPretoPeq .imgBtn,
.btnPadraoPretoPeq .imgBtnPadrao 
{
  align-self: center;
  text-align: right;
  margin:0;
  position: relative;
  left:0;
  transition: all 0.3s ease-in-out;  
}
.btnPadrao:hover .imgBtn, 
.btnPadrao:hover .imgBtnPadrao, 
.btnPadraoPreto:hover .imgBtn,
.btnPadraoPreto:hover .imgBtnPadrao {
  left: 10px;  
}


@media only screen and (max-width: 800px) {
  
  .btnPadraoPreto, 
  .btnPadrao {
    width: 360px;
  }
  .btnPadraoPretoPeq, 
  .btnPadraoPeq {
    width: 250px;
  }  
}
.backgroundModal-TourModal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  display: flex;
  flex-direction: column;
}

.title-TourModal {
  max-width: 370px;
  height: 250px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #fff;
  border-radius: 4px;
  margin-top: 60px;
  padding: 20px;
  display: flex;
  align-items: center;
}

.title-TourModal span {
  font-weight: bold;
  cursor: pointer;
}
.btn-Modal {
  /* margin-top: 35px; */
}
.perfilModal {
  display: flex;
  justify-content: center;
}

.conteudoModal-Tour {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
 
}

.actions-TourModal {
  min-width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.conteudoModal-Tour h2 {
  /* font: italic normal 600 20px Segoe TV; */
  color: #ff5e00;
  /* margin-top: 20px; */
  /* margin-bottom: 10px; */
}
.conteudoModal-Tour h3 {
  color: var(--unnamed-color-303030);
  /*  font: italic normal 600 15px/21px Segoe TV; */
  color: #303030;
  text-align: center;
}

.buttonModal-Tour {
  margin-top: 15px;
  color: white;
  background-color: #ff5e00;
  padding: 10px;
  border-radius: 5px;
}

.cancel-button-TourModal {
  margin-top: 15px;
  color: white;
  background-color: #303030;
  padding: 10px;
  border-radius: 5px;
}

.cancel-button-TourModal:hover {
  background-color: #3030309d;
}

.buttonModal-Tour:hover {
  background-color: #fc9b62;

}

.conteudoModal-Tour h4 {
  color: #838383;
}


.container-principal-SearchingDriver {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  background-image: url(/static/media/map.b89af2b9.svg);
  background-size: cover;
}

.container-SearchingDriver {
  margin: 15px;
}

.container-central-SearchingDriver {
  width: 586px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 10%;
  max-width: 100%;
  position: relative;
  padding-top: 50px;
  box-shadow: 0 0 10px #0000002e;
  padding-bottom: 80px;
}
.numero-pedido-SearchingDriver {
  max-width: 494px;
  height: 62px;
  background: #f5f2f2 0% 0% no-repeat padding-box;
  border-radius: 5px;
  margin: 0 auto;
  top: -30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: relative;
}
.numero-pedido-SearchingDriver h2 {
  color: #ff5e00;
}
.numero-pedido-SearchingDriver input {
  color: #303030;
  font-size: 28px;
  max-width: 100px;
  border: none;
  background: transparent;
}
.btn-acompanhar {
  display: flex;
  width: 284px;
  height: 53px;
  background: #303030;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  color: #ffffff;
  padding: 8px 6px 0px 10px;
  cursor: pointer;
  text-align: left;
  /* font: italic normal 23px/31px Segoe TV; */
  font-size: 23px;
  letter-spacing: 0px;
}

.btn-acompanhar:hover {
  border: 1.5px solid #e3e3e3;
}
.conteudo-searchingDriver {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.conteudo-searchingDriver h2 {
  /* font: italic normal 600 24px Segoe TV; */
  color: #ff5e00;
  margin-top: 20px;
  margin-bottom: 10px;
}
.conteudo-searchingDriver h3 {
  color: var(--unnamed-color-303030);
  /* font: italic normal 600 16px/21px Segoe TV; */
  color: #303030;
}

.conteudo-searchingDriver p {
  /* font: italic normal 600 12px Segoe TV; */
  font-size: 12px;
  color: #838383;
  margin-top: 10px;
  margin-bottom: 40px;
  font-weight: bold;
}
.conteudo-searchingDriver h4 {
  color: #838383;
}
.buttons-searchingDriver button {
  margin-top: 10px;
}
.btn-acompanhar {
  display: flex;
  justify-content: space-between;
}
.container-btn-searchingDriver {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-btn-searchingDriver button {
  margin-top: 10px;
}
.checked-perfil-SearchingDriver {
  display: flex;
  justify-content: center;
}

.container-btn-searchingDriver-qrCode {
  width: 200px;
  height: 200px;
}

.numero-pedido-SearchingDrive-id {
  text-align: right;
  font-size: 20px;
}

.perfilDriver-img {
  width: 79px;
  width: 79px;
  border-radius: 50%;
}

.searchingDriver-loader {
    /* z-index: 1000;
    margin-top: 15px;
    margin-right: 20px; */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    -webkit-animation:spin 6s linear infinite;
    animation:spin 6s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@media only screen and (max-width: 800px) {
  .container-central-SearchingDriver {
    top: 10%;
    width: 90%;
    padding: 50px 30px;
    text-align: center;    
  }
}

.backgroundModal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  display: flex;
  flex-direction: column;
}

.title {
  width: 284px;
  height: 440px;
  background-color: #fff;
  border-radius: 4px;
  margin-top: 35px;
}
.btn-Modal {
  margin-top: 35px;
}
.perfilModal {
  display: flex;
  justify-content: center;
}

.conteudoModal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.conteudoModal h2 {
  /* font: italic normal 600 20px Segoe TV; */
  color: #ff5e00;
  margin-top: 20px;
  margin-bottom: 10px;
}
.conteudoModal h3 {
  color: var(--unnamed-color-303030);
  /*  font: italic normal 600 15px/21px Segoe TV; */
  color: #303030;
}

.conteudoModal h4 {
  color: #838383;
}
.conteudoModal img {
  margin-top: 35px;
  margin-bottom: 10px;
}

.btn-ajuda {
  display: inline-flex;
  height: 40px;
  background: #303030;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  color: var(--color-white);
  padding: 10px 30px 0px 10px;
  cursor: pointer;
  font-size:14px;
}
.btn-ajuda:hover {
  background: #FF5E00;
}

.btn-ajuda h4 {
  margin-left: 40px;
}

.button-chat-main h3 {
  margin-bottom: 6px;
}

@media only screen and (max-width: 800px) {
  .btn-ajuda {
    width: 100%;
  }
  .btn-ajuda h4 {
    margin-left: 85px;
  }
  
}


.jjYngv > .wrapper {
  z-index: 1;
}
.inputProductAdd {
  position: relative;
  width: 100vw;
  max-height: 50vh;
  height: 30vh;
  top: 0;
  left: 0;
  margin-top: 0px;
  z-index: 9999;
  background: white;
}

.loader-format {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  
}
.loader {
  position: absolute;
  z-index: 1000;
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  -webkit-animation:spin 6s linear infinite;
  animation:spin 6s linear infinite;
}

.pesquisar-produtos-autocomplete input {
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 100%;
  min-height: 50px;
  padding-left: 15px;
  max-height: 50px;
  font-size: 22px;
  overflow: auto;
  border: 1px solid #d1d1d1;
}

.pesquisar-produtos-autocomplete-item h2 {
  text-transform: uppercase;
 }

.pesquisar-produtos-autocomplete input::-webkit-input-placeholder {
  color: #c4c4c4;
  font-size: 20px;
}

.pesquisar-produtos-autocomplete input:-ms-input-placeholder {
  color: #c4c4c4;
  font-size: 20px;
}

.pesquisar-produtos-autocomplete input::placeholder {
  color: #c4c4c4;
  font-size: 20px;
}

.pesquisar-produtos-autocomplete-item {
  cursor: pointer;
  font-size: 22px;
  border-bottom: 1px solid #c4c4c4;
  border-left: 1px solid #c4c4c4;
  /* border-right: 1px solid #c4c4c4; */
  padding: 10px;
  width: auto;
  min-width: 1230px;
}

.pesquisar-produtos-autocomplete-item h2 {
  font-size: 0.8em;
}

.pesquisar-produtos-autocomplete-item:hover {
  color: var(--color-1);
}

.pesquisar-produtos-autocomplete-container {
  position: absolute;
  background: #ffffff;
  overflow: auto;
  max-height: 290px;
  height: auto;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 0.1px solid #c0c0c0;
  box-shadow: 0px 10px 10px #0000001a;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@media only screen and (max-width: 800px) {
  .inputProductAdd {
    position: absolute;
    width: 100vw;
    height: 100vh;
    /* margin-top: 200px; */
    /* top: -180px; */
    /* left: -17px; */
    /* margin-top: 30px; */
    z-index: 9999;
    background: white;
  }

  .pesquisar-produtos-autocomplete-item  {
    width: auto;
    min-width: 360px;
  }

  .pesquisar-produtos-autocomplete input {
    font-size: 20px;
  }

 
  .pesquisar-produtos-autocomplete-container  {
    max-height: 300px;

  }

}

.container-principal-Main{ 
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.container-main-central {
  width: 100%;
  padding-bottom: 50px;
}

.search-input-main-container {
  display: inline;
  display: initial;
  width: 360px;
}

.box {
  background-color: #fff;
  margin-bottom: 12px;
  max-width: 100%;
  text-decoration: none; 
  align-items: center;
  color: var(--color-black-2);
  width: 360px;
  height: auto;
  min-height: 90px;
  border: 1px solid #00000033;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  box-shadow: 0px 3px 6px #00000029;
  text-decoration: none;
  transition: all 0.3s ease-in-out;  
}
  .box:hover{
    text-decoration: none;
    color: var(--color-black-2);
  }
  .box-local {
    padding: 6px;
    color: var(--color-1);
    transition: all 0.3s ease-in-out;      
  }  
  .box-local:hover{
    color: var(--color-black-2);
  }
  .box textarea {
    width: 200px;
    height: 70px;
    font-size: 13px;
    resize: none;
    margin-top: 4px;
    border: none;
    cursor: pointer;
    color: var(--color-1);
    text-align: left;

    overflow: hidden;
    background: transparent;
  }
  .box input {
    height: 70px;
    font-size: 20px;
    background: transparent;
    border: none;
    text-align: center;
    cursor: pointer;
    color: var(--color-1);
    margin: -7px;
  }
.checked {
  height: 30px;
  align-self: center;
}
::-webkit-input-placeholder {
  font-size: 23px;
  color: #c0c0c0;
}
:-ms-input-placeholder {
  font-size: 23px;
  color: #c0c0c0;
}
.placeholder, 
::placeholder {
  font-size: 23px;
  color: #c0c0c0;
}
.placeholder{
  width: 30px;
  padding-left: 10px;
}

.informacao-central {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
}
.informacao-central h2 {
  margin-bottom: 15px;
}

.img-input {
  width: 41px;
  height: 35px;
}
.img-box-main {
  margin-top: 12px;
  height: 35px;
}
.icon-caixa {
  display: flex;
  margin-bottom: 10px;
  margin-top: 20px;
}
.icon-caixa h2 {
  padding-top: 12px;
  margin-left: 20px;
}
.icon-caixa h2,
.informacao-central h2{
  font-size: 22px;
}

.imgTransp-main.img {
  margin-top: 25px;
  padding: 100px;
}
.btn-finalizar h5 {
  margin-right: 15px;
}
.posicionamento-main {
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
}
.react-datetime-picker__inputGroup {
  min-width: calc(4px + (4px * 3) + 0.54em * 6 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 75px;
}
.react-datetime-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.container-main {
  /* margin: 15px; */
  display: flex;
  flex-direction: column;
}
.btnPadrao .imgBtnPadrao{
  align-self: auto;
}
.react-datetime-picker.react-datetime-picker--closed.react-datetime-picker--enabled {
  position: absolute;
  top: 0;
  margin-top: 35px;
  width: 100%;
  height: 77px;
  border: 0px solid transparent;
}
.react-datetime-picker__button:enabled {
  cursor: pointer;
  display: none;
}

.react-datetime-picker__inputGroup input,
.react-datetime-picker__inputGroup span {
  font-size: 20px;
  color: var(--color-1);
  border: 0;
  cursor: pointer;
}

.react-datetime-picker__wrapper div {
  margin-left: 80px;
}

.clear-icon {
  display: none;
}
.search-icon {
  order: 2;
  padding-right: 10px;
  width: 50px;
  height: 50px;
}
/* Shopcart : Produtcs */
.container-principal-Main .cx-info-transp-central {
  width: 100%;
  max-height: 280px;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1d1;
  padding: 35px 30px;
  border-radius: 5px;
  cursor: pointer;
  flex-direction: column;
  overflow: auto;
  margin-top:10px;
}
.cx-info-transp-central ul{
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.cx-info-transp-central ul li{
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e4e4e4;
}
.cx-info-transp-central ul li:last-child {
  border: 0;
  margin: 0;
}
.lista-item-OrderResume-input-name {
  width: 100%;
}

.cx-info-transp-central img.btShopCartRemove {
  opacity: 0.5;
  transition: all 0.3s ease-in-out;   
}
.cx-info-transp-central img.btShopCartRemove:hover {
  opacity: 1;  
}

.react-datepicker-wrapper input[type="text"]{
  border: 0;
  box-shadow: none;
  padding: 0;
  background: transparent;  
}
.button-chat{
  margin-top: 30px;
  margin-bottom: 10px;
  text-align: center;
}

/* Input de pesquisa de produtos */
.pesquisar-produtos{
  flex-direction: column;
  z-index: 1000;
  position: relative;
}
.pesquisar-produtos-autocomplete input:first-child{
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  text-decoration: none;
  transition: all 0.3s ease-in-out;    
}
.pesquisar-produtos-autocomplete{
  border-radius: 4px;
  height: 70px;
  max-height: 100%;
  font-size: 22px;
}
.informacao-central-select {
  /* display: flex; */
  justify-content: space-between;
  margin-top: 18px;
}


@media only screen and (max-width: 1280px) {
  .informacao-central-select {
    /* display: block; */
    justify-content: space-between;
    margin-top: 18px;
    align-items: center;
  }

  .informacao-central-select input{
    width: 100%;
  }
  
  .search-input-main-container{
    display: flex;
    flex-direction: column;
    background-color: transparent;
    margin-bottom: 12px;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: auto;
    height: auto;
    min-height: 90px;
    min-width: 100%;

  }
  .pesquisar-produtos-autocomplete-container {
    max-height: 300px;
    width: 100%;
  }
  /* .container-principal {
      height: auto;
  }
   */
  .informacao-central {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .btn-voltar {
    margin-left: 15px;
  }
  .posicionamento-main {
    align-items: center;
    flex-direction: column;
    margin-top:30px;
    margin-bottom: 30px;
  }
  .imgTransp-main {
    margin-top: 8px;
  }
  .rdt{
    width:200px;
  }
  .btn-ajuda {
    margin-bottom: 13px;
  }
  .container-main-central {
    height: auto;
    margin-bottom: 30px;
  }
  .campo-busca-main-central {
    width: 360px;
    /* max-width: 100%; */
    /* margin: 0 auto; */
    /* overflow: auto; */
  }
  .icon-caixa {
    justify-content: center;
  }

  .container-inferior-cinza {
    height: auto;
    margin-top: 50px;
  }
  /* Shopcart : Produtcs */
  .container-principal-Main .cx-info-transp-central {
    margin-top: 30px;
  }
  .container-principal-Main .cx-info-transp-central li {
    font-size: 14px;
    overflow: hidden;
  }
  .container-principal-Main .cx-info-transp-central input {
    font-size: 15px;
  }
  .cx-info-transp-central-div-span {
    font-size: 15px;
  }  
  .container-main-central .imgVoltar {
    margin: 30px 0px 0px 30px;
  }
  .react-datepicker-wrapper input[type="text"]{
    border: 0;
    box-shadow: none;
    padding: 0;
    background: transparent;
  }
  .react-datepicker-wrapper ::-webkit-input-placeholder{
    font-size: 22px!important;
  }
  .react-datepicker-wrapper :-ms-input-placeholder{
    font-size: 22px!important;
  }
  .react-datepicker-wrapper ::placeholder{
    font-size: 22px!important;
  }

  #modal-content-52, #modal-content-5{
    margin-top: 100px;
  }

  .btn-finalizar {
      margin-top: 30px;
  }  
}




/* Package */
.packages {
  margin-top: 25px;
}
.packages ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.packages li {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #d0d0d0;
  box-shadow: 0px 0px 10px #0000000d;
  height: 500px;
  margin-right: 20px;
  transition: all 0.3s ease-in-out;   
  cursor:pointer;
}
.packages li:last-child {
  margin-right: 0;
}
.packages li:hover{
  border: 1px solid #c0c0c0;
  box-shadow: 0px 0px 20px #0000002a;
}
.packages li h2 {
  text-align: center;
  font-size: 20px;
  color: var(--color-1);
  margin-bottom: 20px;
}
.packages li p {
  text-align: justify;
  margin-bottom: 10px;
  white-space: break-spaces;
  height: 100%;
  width: 100%;
}
.packages li button {
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;  
}
.packages li button div {
  width: 100%;
  text-align: center;
}
.packages li button input[type="checkbox"] {
  width: 18px;
  height: 18px;
}

@media only screen and (max-width: 1280px) {
  .packages ul{
    flex-direction: column;
  }
  .packages li {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
}
.react-datetime-picker__inputGroup__input:invalid {
  background: none;
}
.react-datetime-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: none;
  border-radius: 4px;
}
.react-datetime-picker__calendar,
.react-datetime-picker__clock {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9999;
}

@media only screen and (max-width: 1200px) {
  .react-datetime-picker__wrapper div {
    margin-left: 0px;
  }
  .react-datetime-picker.react-datetime-picker--closed.react-datetime-picker--enabled {
    width: 0px;
  }
  .react-datetime-picker.react-datetime-picker--closed.react-datetime-picker--enabled {
    width: 0px;
    margin-left: -100px;
  }
  element.style {
    width: 314.2px;
  }
}

/* Fixes the bug line on mobile mobile devices on rainbow date time picker */
@media only screen and (max-width: 800px) {
  section {
    min-height: 380px;
  }
}
.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  min-width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */ /* Konqueror */ /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-of-type th {
  cursor: pointer;
}
.rdtPicker thead tr:first-of-type th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #000;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */ /* Konqueror */ /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

.container-principal-productAdd {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.container-productAdd {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  background: white;
  z-index: -1;
}

.positionItems-ProductsAdd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}
.positionItems-ProductsAdd input {
  height: 70px;
  border: none;
  font-size: 28px;
  text-align: center;
}
.btn-voltar-productAdd {
  margin: 15px 0px 10px 15px;
}
.container-inferior-cinza-productAdd {
  display: flex;
  width: 100%;
  max-height: 100%;
  background: transparent linear-gradient(180deg, #c9c9c9 0%, #e9e9e9 100%) 0%
    0% no-repeat padding-box;
  flex: auto;
  text-align: center;
}
.btn-productAdd {
  display: flex;
}

.btn-productAdd button {
  width: 90px;
  background: #303030 0% 0% no-repeat padding-box;
  box-shadow: #00000029;
  border-radius: 3px;
  cursor: pointer;
}
.container-central-productAdd {
  display: flex;
  justify-content: space-between;
}
.campo-dimensoes-productAdd {
  display: flex;
  justify-content: space-between;
  margin-left: 28px;
}

.campo-dimensoes-productAdd input {
  width: 45px;
  height: 30px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
}
.campo-dimensoes-productAdd h5 {
  color: #ff5e00;
}

.container-items-productAdd {
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.linha-vertical {
  border-left: 2px solid #e3e3e3;
}
.container-observacoes {
  width: 100%;
}
.container-observacoes textarea {
  width: 100%;
  height: 307px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  font-size: 22px;
  padding: 10px;
  resize: none;
  max-width: 100%;
}
.container-observacoes textarea::-webkit-input-placeholder {
  padding: 4px 0px 0px 8px;
}
.container-observacoes textarea:-ms-input-placeholder {
  padding: 4px 0px 0px 8px;
}
.container-observacoes textarea::placeholder {
  padding: 4px 0px 0px 8px;
}
.container-observacoes h1 {
  color: #ff5e00;
  font-size: 28px;
  margin-bottom: 30px;
}
.margin-productAdd {
  margin: 15px;
}
.imgDimensoes {
  margin-top: 43px;
  margin-left: 36px;
}
.input-dimensoes {
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
}
.input-dimensoes input {
  text-align: center;
}


.input-dimensoes-error {
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
}
.input-dimensoes-error input {
  text-align: center;
  border: 3.5px solid rgb(231, 147, 147);
}

.img-busca-productAdd {
  z-index: 9999;
  position: absolute;
  color: #303030;
  padding: 17px 0px 0px 140px;
}
.input-ProductsAdd {
  max-width: 370px;
  margin-left: 14px;
  max-width: 364px;
}
h3.productsAddName {
  margin-bottom: 20px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  font-size: 22px;
  padding: 10px;
}

@media only screen and (max-width: 1280px) {
  .container-central-productAdd {
    flex-direction: column;
    text-align: center;
  }
  .positionItems-ProductsAdd {
    flex-direction: column;
    text-align: center;
  }
  .positionItems-ProductsAdd {
    margin-top: none;
  }
  .positionItems-ProductsAdd input {
    width: 100%;
    min-width: 300px;
    max-width: 180px;
  }

  .positionItems-ProductsAdd button {
    width: 100%;
  }

  .container-observacoes {
    margin-top: 40px;
  }

  .input-dimensoes {
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
  }
  .btn-productAdd button {
    width: 120px;
  }
  .positionItems-ProductsAdd input {
    min-width: 90px;
    max-width: 70%;
  }

  .imgDimensoes {
    margin-top: 43px;
    text-align: left;
    /* margin-left: 20px; */
  }
  .btn-productAdd {
    margin: 23px 0;
  }

  .campo-dimensoes-productAdd {
    margin-left: -12px;
  }

  .container-observacoes {
    border-left: none;
    margin-left: 0px;
  }

  .container-observacoes h1 {
    text-align: center;
  }

  .container-observacoes textarea {
    padding: 15px;

  }
  .container-observacoes h1 {
    margin-left: 0px;
  }
  .btn-voltar-productAdd {
    margin-left: 28px;
  }
  .margin-productAdd {
    margin: 0px;
  }

.button-custom {
  margin-bottom: 22px;
  max-width: 100%;
  text-align: center;
}

.margin-productAdd h1 {
  text-align: center;
}
 /* .container-controllers{
   display: flex;
 } */

}

.rc-tooltip.rc-tooltip-zoom-appear,
.rc-tooltip.rc-tooltip-zoom-enter {
  opacity: 0;
}
.rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}
.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
          animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-tooltip-zoom-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
          animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  -webkit-animation-name: rcToolTipZoomIn;
          animation-name: rcToolTipZoomIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  -webkit-animation-name: rcToolTipZoomOut;
          animation-name: rcToolTipZoomOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@-webkit-keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  line-height: 1.5;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 1px;
  /* opacity: 0.9; */
}
.rc-tooltip-hidden {
  display: none;
}
.rc-tooltip-inner {
  padding: 8px 9px;
  color: #333333;
  text-align: center;
  text-decoration: none;
  background-color: #ffffff;
  border-radius: 3px;
  min-height: 34px;
  border: 1px solid #b1b1b1;
}
.rc-tooltip-arrow,
.rc-tooltip-arrow-inner {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  bottom: -5px;
  margin-left: -6px;
  border-width: 6px 6px 0;
  border-top-color: #b1b1b1;
}
.rc-tooltip-placement-top .rc-tooltip-arrow-inner,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow-inner,
.rc-tooltip-placement-topRight .rc-tooltip-arrow-inner {
  bottom: 1px;
  margin-left: -6px;
  border-width: 6px 6px 0;
  border-top-color: #ffffff;
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}
.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  left: -5px;
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
  border-right-color: #b1b1b1;
}
.rc-tooltip-placement-right .rc-tooltip-arrow-inner,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow-inner,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow-inner {
  left: 1px;
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
  border-right-color: #ffffff;
}
.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  right: -5px;
  margin-top: -6px;
  border-width: 6px 0 6px 6px;
  border-left-color: #b1b1b1;
}
.rc-tooltip-placement-left .rc-tooltip-arrow-inner,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow-inner,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow-inner {
  right: 1px;
  margin-top: -6px;
  border-width: 6px 0 6px 6px;
  border-left-color: #ffffff;
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  top: -5px;
  margin-left: -6px;
  border-width: 0 6px 6px;
  border-bottom-color: #b1b1b1;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow-inner,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow-inner,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow-inner {
  top: 1px;
  margin-left: -6px;
  border-width: 0 6px 6px;
  border-bottom-color: #ffffff;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}

@media only screen and (max-width: 1280px) { 

  .rc-tooltip {
    background-color: transparent;
  }
  .rc-tooltip-inner {
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.container-principal-OrderResume {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.container-OrderResume {
  margin: 14px;
}
.container-OrderResume-inferior {
  display: flex;
  width: 100%;
  height: 215px;
  background: transparent linear-gradient(180deg, #c9c9c9 0%, #e9e9e9 100%) 0%
    0% no-repeat padding-box;
  flex: auto;
}

.cx-info-transp, 
.cx-info-transp-central{
  width: 600px;
  height: 310px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  /*  margin-left: 10px; */
  display: flex;
  padding: 35px 30px;
  cursor: pointer;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
}
.cx-info-transp-central{
  padding-top:0;
}

/* checkbox desktop css */
.cx-info-transp-checkbox {
  width: 100%;
  min-height: 100px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  /*  margin-left: 10px; */
  display: flex;
  padding: 35px 30px;
  cursor: pointer;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.opcoes-transp-content-checkbox {
  display: flex;
  align-items: center;
}
.opcoes-transp-content-checkbox > h4 {
  margin-left: 20px;
}

.container-resumo-transp {
  display: flex;
  width: 100%;
  margin-top: 47px;

  justify-content: space-between;
}
.container-resumo-transp h4 {
  margin-left: 10px;
  color: #303030;
}

.container-central-OrderResume h1 {
  color: #ff5e00;
  font-size: 24px;
  text-align: center;
  margin-bottom: 45px;
}
.container-central-OrderResume img {
  cursor: pointer;
}
.container-central-OrderResume h3 {
  margin-bottom: 10px;
  font-size: 16px;
  height: auto;
}
.container-central-OrderResume {
  margin-top: 10px;
  width: 100%;
  height: auto;
  height: auto;
  margin-bottom: 30px;
}
.switch-OrderResume {
  display: flex;

  
}

.switch-OrderResume h4 {
  margin-bottom: 15px;
  margin-left: 13px;
  margin-top: 15px;
}

.timer {
  display: flex;
  font-size: 16px;
  color: #ff5e00;
  align-items: center;
}
.timer img {
  width: 16px;
  margin-right: 10px;
}
.timer input {
  width: 200px;
  height: 40px;
  border: none;
  background-color: transparent;
  text-align: right;
  /* font: italic normal 600 21px/41px Segoe TV; */
  font-size: 21px;
  letter-spacing: 0px;
  color: #303030;
  font-weight: bold;
}
.total {
  display: flex;
  justify-content: space-between;
  /* font: italic normal 600 32px Segoe TV; */
  font-size: 32px;
  color: #ff5e00;
  align-items: center;
}

.total input placeholder {
  text-align: right;
  font-weight: bold;
}
.total span {
  width: 100px;
  height: 49px;
  border: none;
  background-color: transparent;
  font-size: 32px;
  align-self: center;
  text-align: right;
  /* font: italic normal 600 37px/49px Segoe TV; */
  font-size: 37px;
  letter-spacing: 0px;
  color: #303030;
  font-weight: bold;
  margin-left: 20px;
}

.switch-OrderResume {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.switch-OrderResume img {
  margin-left: 100px;
}
hr{
  border: 1px solid #ff5e00;
  height: 0px;
  padding:0;
}
.opcoes-transp {
  align-items: center;
  margin-bottom: 35px;
  display: flex;
}
.opcoes-transp input {
  margin-left: 20px;
  border: none;
  /*  font: italic normal 600 14px/19px Segoe TV; */
  font-size: 14px;
  width: 100%;
  font-weight: bold;
}
.opcoes-transp-opcionais {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.opcoes-transp-opcionais h5 {
  margin-left: 10px;
}
.img-interrogacao {
  margin-left: auto;
}

.img-confirmar-OrderResume {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}
.posicionamento-order-resume {
  display: flex;
  justify-content: space-between;
}

.lista-item-OrderResume {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 17px;
}
.lista-item-OrderResume-input-name {
  align-items: center;
  color: #303030;
  font-weight: bold;
  width: 100%;
  font-size: 16px;
}

lista-item-OrderResume img {
  margin-left: 30px;
}

lista-item-OrderResume-observations {
  text-align: left;
}

.lista-item-OrderResume-input-number {
  width:40px;
  height: 40px;
  text-align: center;
  color:  #ff5e00;
  overflow-x: hidden;
  display: inline-flex;
  align-items: center;
  align-content: center;
  align-self: center;
  font-size: 20px;
  font-weight: bold;
}

.cx-info-transp-central img {
  margin-left: 30px;
}

.opcoes-transp input{
  font-size: 24px;
  font-weight: normal;
}
.local-trans{
  color: #303030;
  margin-left: 20px;
  text-align: left;
  font-size: 16px;
}

span.totalLoading {
  padding: 0;
  margin: 0;
  color: #666;
}

.react-tool-tip {
  position: absolute !important;
}

.button-chat{
  margin-top: 30px;
  margin-bottom: 10px;
  text-align: center;
  margin-bottom: 20px;
}
  .button-chat p{
    margin-bottom: 10px;
  }

@media only screen and (max-width: 1100px) {
  .container-resumo-transp {
    flex-direction: column;
    align-items: center;
    /* text-align: center; */
  }
  .container-central-OrderResume h3 {
    text-align: center;
    font-size: 22px;
  }
  .cx-info-transp {
    width: 350px;
    margin-bottom: 30px;
    overflow: hidden;
  }
  .container-central-OrderResume h1 {
    margin-bottom: 23px;
    margin-top: 30px;
  }
  .posicionamento-order-resume {
    justify-self: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;
  }
  .timer h3 {
    font-size: 15px;
  }
  .switch-OrderResume {
    justify-content: center;
    margin-left: 15px;
  }
  .posicionamento-order-resume div {
    margin-bottom: 15px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .img-confirmar-OrderResume {
    justify-content: flex-start;
  }

  .total span {
    /* margin: auto, -32px; */
    text-align: center;
    width: 200px;
    margin: 0;
  }

  .cx-info-transp-central {
    margin-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: 350px;
    max-height: 500px;
    height: auto;
  }

  .cx-info-transp-central li {
    font-size: 14px;
    overflow: hidden;
    
  }

  .cx-info-transp-central input {
    font-size: 15px;

 
  }

  .cx-info-transp-central-div-span {
    font-size: 15px;
    /* padding: 0; */
 
  }

  /* checkbox mobile css */
  .cx-info-transp-checkbox {
    width: 350px;
    max-height: 500px;
  }

  .lista-item-OrderResume-input-number {
    /* margin-left: 30px; */
    width:40px;
    height: 35px;
    /* border: 1px solid #E3E3E3; */
    text-align: center;
    color:  #ff5e00;
    display: inline-flex;
    align-items: center;
    align-content: center;
    align-self: center;
    font-size: 20px;
    font-weight: bold;
  }

  .lista-item-OrderResume-input-number input {
    color:  #ff5e00;
    font-size: 20px;

  }


  .lista-item-OrderResume-observations {
    /* text-align: center; */
    margin-left: 30px;
  }

  .container-OrderResume-inferior {
    height: 100%;
  }

  .img-confirmar-OrderResume {
    justify-content: center;
  }

  .total {
    margin-left: 0;
  }

  .switch-OrderResume h4 {
    font-size: 21px;
  }
  
} 


@media only screen and (max-width: 1280px) { 
  
}

.button-radio-container {
  /* width: 464px; */
  display: flex;
  align-items: center;
  /* justify-content: space-between;; */
}
input[type='checkbox'] {
  display: none;
}

.radio-container label {
  display: flex;
  align-items: center;
  /* column-gap: 20px; */
}

.custom-radio {
  border: 1px solid #9b9b9b;
  width: 25px;
  height: 25px;
  display: grid;
  place-content: center;
  border-radius: 20%;
}

.custom-radio {
  transition: 0.5s ease;
  color: transparent;
  font-size: 22px;
}

.custom-radio span {
  content: '';
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 30%;
}

input[type='checkbox']:checked + .custom-radio {
  border-color: #9b9b9b;
}

input[type='checkbox']:checked + .custom-radio span {
  background: orangered;
}
.container-principal-OrderContact{
  width: 100vw;
  height: 1230px;
  margin: 0 auto;
  max-height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.margin-order-contact {
  margin: 15px;
}
.vlr-entrega-orderContact {
  width: 464px;
  height: 101px;
  background: rgba(141, 128, 128, 0.09);
  border-radius: 5px;
  align-items: flex-start;
  padding: 30px;
  display: flex;
  align-items: center;
  align-items: baseline;
  justify-content: space-between;     
}

.vlr-entrega-orderContact h1 {
  color: var(--color-1);
  font-size: 25px;
}
.vlr-entrega-orderContact-price {
  /* width: 111px; */
  /* height: 44px; */
  color: var(--unnamed-color-303030);
  text-align: left;
  /*   font: italic normal 33px Segoe TV; */
  font-size: 29px;

  color: #303030;
  border: none;
  background-color: transparent;
  margin-left: 20px;
  
}
.vlr-orderContact {
  display: flex;
  align-items: inherit;
}
.vlr-orderContact h3 {
  margin-right: 5px;
}
.cartao-orderContact {
  max-width: 464px;
  height: 250px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.cartao-orderContact div {
  max-width: 414px;
  height: 70px;
  background: #e1e1e1 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 0.42;
}
.posicionamento-inferior-orderContact {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  align-items: center;
}
.btn-finalizar-orderContact div {
  margin-top: 10px;
  text-align: right;
}
.btn-finalizar-orderContact div img {
  max-height: 38px;
}
.btn-finalizar-orderContact-wait div img {
  -webkit-animation:spin 6s linear infinite;
  animation:spin 6s linear infinite;
}
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }   

.container-central-orderContact {
  display: flex;
  justify-content: space-between;
}
.divisão-orderContact {
  border-left: 2px solid #707070;
  opacity: 0.3;
}
.input-date-cod-orderContact {
  display: flex;
  /* width: 100%; */
  flex-direction: column;
}
.switch-orderContact {
  display: flex;
  /* align-items: end; */
  align-items: flex-end;
  margin-top: 30px;
}
.switch-orderContact span {
  margin-left: 10px;
}
.cupon-orderContact {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 75px;
}

.cupon-orderContact input {
  width: 207px;
  height: 63px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  font-size: 18px;
  text-align: center;
  box-shadow: 0px 3px 6px #00000029;
  text-decoration: none;
  transition: all 0.3s ease-in-out;  
}
.container-central-orderContact h2 {
  margin-bottom: 15px;
  color: #ff5e00;
  font-size: 20px;
}
.form-local-coleta-orderContact {
  margin-bottom: 65px;
  /* min-width: 464px; */
}
/* .form-local-entrega-orderContact {
  min-width: 464px;
} */

.formOrderContact input {
  max-width: 464px;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  font-size: 25px;
  text-align: center;
  box-shadow: 0px 3px 6px #00000029;
  text-decoration: none;
  transition: all 0.3s ease-in-out;  
  text-align: center;
}

.formOrderContact select {
  max-width: 464px;
  width: 100%;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  font-size: 25px;
  text-align: center;
  box-shadow: 0px 3px 6px #00000029;
  text-decoration: none;
  transition: all 0.3s ease-in-out;  
  text-align: center;
  margin-bottom: 13px;

}

/* on error */
.formOrderContact-error input {
  max-width: 464px;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 4px solid #EDADAD;
  border-radius: 3px;
  font-size: 25px;
  text-align: center;
  box-shadow: 0px 3px 6px #00000029;
  text-decoration: none;
  transition: all 0.3s ease-in-out;  
  text-align: center;
}
.toggle-coleta-orderContact {
  display: flex;
  margin-bottom: 20px;
  color: #505050;
}

.container-central-orderContact h4 {
  color: #505050;
  font-size: 17px;
  margin-left: 20px;
}

.cupon-orderContact input {
  width: 170px;
  height: 70px;
  font-size: 22px;
}

.container-OrderResume-inferior-tooltip {
  width: 20px;
}



/* Fix Toastify X button shadow */
.Toastify__close-button--light {
  box-shadow: none!important;
}

/* Payment QRCode */
.payment-qrcode {
  width: 464px;
}
.qrcode-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
}
.qrcode-image img {
  width: 100%;
}
.payment-text {
  width: 100%;
}
.payment-text input {
  padding: 10px;
  margin: 10px 0;
  width: 100%;
}

.button-chat{
  margin-top: 30px;
  margin-bottom: 10px;
  text-align: center;
  margin-bottom: 20px;
}
  .button-chat p{
    margin-bottom: 10px;
  }

@media only screen and (max-width: 1280px) {
  .container-central-orderContact {
    flex-direction: column;
  }
  .formOrderContact {
    max-width: 464px;
  }

  .vlr-entrega-orderContact h1 {
    color: var(--color-1);
    font-size: 23px;
    flex-direction: column;
  }

  .vlr-entrega-orderContact price {
    align-items: center;
  }

  .formOrderContact input {
    display: flex;
    flex-direction: column;
    /* min-width: 100%; */
  }

  

  .posicionamento-inferior-orderContact {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .btn-finalizar-orderContact div {
    text-align: center;
  }
  .cupon-orderContact {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .cupon-orderContact input {
    width: 360px;
    margin-bottom: 10px;
    /* width: 300px; */

  }
  .input-date-cod-orderContact {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }
  .container-central-orderContact h4 {
    font-size: 14px;
  }
  
  .vlr-entrega-orderContact { 
    margin-top: 20px;
    width: 100%;
  }
  .payment-qrcode {
    width: 100%;
    text-align: center;
  }

  .btn-finalizar-orderContact {
    margin-top: 10px;
  }

  .switch-orderContact {
    justify-content: center;
  }

  .switch-OrderResume {
    margin-left: 5px;
  }
  .switch-OrderResume img {
    margin-left: 20px;
  }

}
.toggle-container {
  width: 40px;
  background-color: #dcdcdc;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 100px;
  border: 1px solid #e0e0e0;
  padding: 1.5px;
  height: 22px;
  position: relative;
  display: flex;
  align-items: center;   
}
.dot {
  cursor: pointer;
  padding: 8px 8px;
  border-radius: 18px;
  min-width: 46px;
  justify-content: center;
  min-width: unset;
  border-radius: 100px;
  box-sizing: border-box;
  position: absolute;
  left: 2px;
  transition: all 0.3s ease;
  margin: 0px;
}
.toggle-container.on{
  background-color: #fff;
}
.toggle-container.off{
  background-color: #fff;
}
.toggle-container.on .dot{
  left:20px;
  background-color: #ff5e00;
  color: #ff5e00;  
}
.toggle-container.off .dot{
  left:3px;
  background-color: #c0c0c0;
  color: #c0c0c0;  
}

@media only screen and (max-width: 800px) {
  .toggle-container {
    height: 21px;
  }
}
.backgroundModal-PaymentModal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  display: flex;
  flex-direction: column;
}

.title-PaymentModal {
  width: 500px;
  height: 250px;
  background-color: #fff;
  border-radius: 4px;
  /* margin-top: 60px; */
  padding: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 170px;
}

.title-PaymentModal span {
  font-weight: bold;
  cursor: pointer;
}
.btn-Modal {
  /* margin-top: 35px; */
}
.perfilModal {
  display: flex;
  justify-content: center;
}

.conteudoModal {
  display: flex;
  flex-direction: column;
  align-items: center;
 
}

.conteudoModal h2 {
  /* font: italic normal 600 20px Segoe TV; */
  color: #ff5e00;
  /* margin-top: 20px; */
  /* margin-bottom: 10px; */
}
.conteudoModal h3 {
  color: var(--unnamed-color-303030);
  /*  font: italic normal 600 15px/21px Segoe TV; */
  color: #303030;
  text-align: center;
}

.buttonModal {
  margin-top: 15px;
  color: white;
  background-color: #fc9b62;
  padding: 10px;
  border-radius: 5px;
}

.buttonModal:hover {
  background-color: #ff5e00;

}

.conteudoModal h4 {
  color: #838383;
}


.container-UserData {
  margin: 15px;
}
.container-UserData-inferior {
  display: flex;
  justify-content: flex-end;
  margin-top: 75px;
}
.container-form-UserData {
  display: flex;
  justify-content: center;
}
.formulario-userData {
  margin-top: 60px;
  width: 618px;
}
.form-userData {
  /* font: italic normal 18px/24px Segoe TV; */
  letter-spacing: 0px;
  color: #303030;
}
.posicao-img-user {
  margin: 48px 300px 40px 0px;
}
.imgVoltar-UserData h1 {
  margin: 6px 0px 0px 15px;
  color: #ff5e00;
}
/* .imgVoltar-UserData {
  margin-top: 35px;
} */

.button-upload-userData {
  display: flex;
  align-items: center;
  align-self: center;
  align-self: center;
}

/* .button-upload-image-userData {
  width: "40px";
  margin-right: "10px";
  margin-top: "10px";
} */
.profile-image-user-data {
  display: inline-block;
  width: 300px;
  height: 300px;
  border-radius: 50%;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@media only screen and (max-width: 1200px) {
  .posicao-img-user {
    margin: 60px 0px 40px 0px;
  }
  .container-form-UserData {
    flex-direction: column;
    max-width: 100%;
    align-items: center;

  }
  
  .formulario-userData {
    margin-top: 20px;
    width: 100%;
  }
  .container-UserData-inferior {
    flex-direction: column;
    align-items: center;
  }
}

.backgroundModal-ModalBase {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.89);
  position: fixed;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  display: flex;
  flex-direction: column;
}

.title-ModalBase {
  max-width: 370px;
  height: 250px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #fff;
  border-radius: 4px;
  margin-top: 60px;
  padding: 20px;
  display: flex;
  align-items: center;
}

.title-ModalBase span {
  font-weight: bold;
  cursor: pointer;
}
.btn-Modal {
  /* margin-top: 35px; */
}
.perfilModal {
  display: flex;
  justify-content: center;
}

.conteudoModal- {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
 
}

.actions-ModalBase {
  min-width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.conteudoModal-Base h2 {
  /* font: italic normal 600 20px Segoe TV; */
  color: #ff5e00;
  text-align: center;
  /* margin-top: 20px; */
  /* margin-bottom: 10px; */
}
.conteudoModal-Base h3 {
  color: var(--unnamed-color-303030);
  /*  font: italic normal 600 15px/21px Segoe TV; */
  color: #303030;
  text-align: center;
}

.buttonModal-Base {
  margin-top: 15px;
  color: white;
  background-color: #ff5e00;
  padding: 10px;
  border-radius: 5px;
}

.cancel-button-ModalBase {
  margin-top: 15px;
  color: white;
  background-color: #303030;
  padding: 10px;
  border-radius: 5px;
}

.cancel-button-ModalBase:hover {
  background-color: #3030309d;
}

.buttonModal-Base:hover {
  background-color: #fc9b62;

}

.conteudoModal-Base h4 {
  color: #838383;
}


.container-LegalInfoPage {
  margin: 15px;
}

.container-LegalInfoPage h1 {
  text-align: left;
  /*  font: italic normal 600 26px/35px Segoe TV; */
  letter-spacing: 0px;
  color: #ff5e00;
}

.container-LegalInfoPage p {
  text-align: justify;
  /* font: normal normal normal 14px/19px Segoe TV; */
  font-size: 14px;
  letter-spacing: 0px;
  color: #707070;

  margin-top: 20px;
  text-justify: center;


}

.icontent{
    width: 100%;
    height: 100%;
    min-height:500px;
    border:0;
    position: relative;
}
.container-principal-payments {
  width: 100vw;
  /* height: 1230px; */

  height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.margin-payments {
  margin: 15px;
}

.container-central-payments {
  display: flex;
  justify-content: space-between;
}
.container-central-payments h1 {
  text-align: left;
  /* font: italic normal 600 26px/35px Segoe TV; */
  letter-spacing: 0px;
  color: #ff5e00;
}
.container-central-payments h3 {
  text-align: left;
  /* font: normal normal normal 14px/19px Segoe TV; */
  letter-spacing: 0px;
  color: #707070;
  margin-top: 20px;
}

.divisao-payments {
  border-left: 2px solid #707070;
  opacity: 0.3;
}

.cartao-payments {
  width: 336px;
  height: 182px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.cartao-payments div {
  width: 301px;
  height: 50px;
  background: #e1e1e1 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 0.42;
}

.input-date-cod-payments {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.input-date-cod-payments::-webkit-input-placeholder {
  text-align: center;
}

.input-date-cod-payments:-ms-input-placeholder {
  text-align: center;
}

.input-date-cod-payments::placeholder {
  text-align: center;
}
.switch-payments {
  margin-top: 15px;
  margin-bottom: 30px;
  display: flex;
}
.switch-payments h5 {
  color: var(--unnamed-color-303030);
  text-align: left;
  /*   font: italic normal 600 13px/19px Segoe TV; */
  letter-spacing: 0px;
  color: #303030;
  margin-left: 10px;
}

.container-payments-right h3 {
  text-align: left;
  /* font: normal normal normal 14px/19px Segoe TV; */
  letter-spacing: 0px;
  color: #707070;
}
.container-payments-right h1 {
  text-align: left;
  /*   font: italic normal 600 26px/35px Segoe TV; */
  letter-spacing: 0px;
  color: #ff5e00;
}

.input-cartao-payments {
  width: 560px;
  min-width: 100px;
  height: 57px;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: self-end;
  color: #e4e4e4;
  padding: 15px;
}
.img-payments {
  margin-left: 10px;
  cursor: pointer;
}
.input-cartao-payments p {
  font-size: 60px;
  /*   letter-spacing: 15px; */
}
.min-cartao-payments {
  width: 82px;
  height: 45px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 4px;
  top: 10px;
}
.min-centro-cartao-payments {
  width: 74px;
  height: 12px;
  background: #e1e1e1 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 0.42;
}
.input-cartao-payments input {
  border: none;
  text-align: right;
  padding: 5px;
  color: #707070;
}
.payments {
  display: flex;
  margin-top: 15px;
  align-items: center;
}
/* .form-payments ::placeholder {
  text-align: center;
  font-size: 18px;
} */

.form-payments input {
  display: flex;
  width: 100%;
  height: 63px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  /* font-size: 18px; */
  text-align: center;
  box-shadow: 0px 3px 6px #00000029;
  text-decoration: none;
  transition: all 0.3s ease-in-out;  
}

.input-date-cod-payments-last-input {
  display: flex;
  width: 100%;
  height: 63px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  font-size: 25px;
  text-align: center;
  box-shadow: 0px 3px 6px #00000029;
  text-decoration: none;
  transition: all 0.3s ease-in-out;  
}
.input-date-cod-payments input::-webkit-input-placeholder {
  margin: 0;
  text-align: center;
}
.input-date-cod-payments input:-ms-input-placeholder {
  margin: 0;
  text-align: center;
}
.input-date-cod-payments input::placeholder {
  margin: 0;
  text-align: center;
}


.btn-finalizar-payments{
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-finalizar-payments button {
  width: 300px;
}

@media only screen and (max-width: 1200px) {
  .container-central-payments {
    flex-direction: column;
    align-items: center;
  }
  .container-payments-left {
    max-width: 100%;
  }

  .container-central-payments h1 {
    text-align: center;
  }

  .container-payments-right h1 {
    margin-top: 15px;
    text-align: center;
  }

  .container-payments-right h3 {
    text-align: center;
  }
  .input-cartao-payments input {
    /* text-align: center; */
  }
/* 
  .form-payments input {
    display: column;
  } */

  .input-date-cod-payments input{
    width: 50%;
  }

}
@media only screen and (max-width: 500px) {
  .input-cartao-payments input {
    /*  text-align: inherit; */
  }

  .btn-finalizar-payments button{
    width: 300px;
  }
  .input-cartao-payments p {
    font-size: 30px;
    text-align: right;
  }
}

.container-HelpPage {
  margin: 15px;
}
.btn-voltar-helpPage {
  margin: 15px;
}

.container-HelpPage h1 {
  text-align: left;
  /* font: italic normal 600 26px/35px Segoe TV; */
  letter-spacing: 0px;
  color: #ff5e00;
  margin-left: 15px;
}

.container-superior-HelpPage {
  display: flex;
  justify-content: space-between;
}

.container-HelpPage h2 {
  text-align: left;
  /*  font: italic normal 600 16px/21px Segoe TV; */
  letter-spacing: 0px;
  color: #ff6811;
  margin-top: 65px;
}

.container-HelpPage p {
  text-align: left;
  /* font: normal normal normal 14px/19px Segoe TV; */
  letter-spacing: 0px;
  color: #707070;
  margin-top: 15px;
  text-align: justify;
}

.container-HelpPage h3 {
  text-align: left;
  /*  font: italic normal 600 16px/21px Segoe TV; */
  font-size: 16px;
  letter-spacing: 0px;
  color: #4b4b4b;
  margin-top: 20px;
}

.btn-ajuda-HelpPage {
  display: flex;
  width: 403px;
  height: 52px;
  background: #303030;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  color: #ffffff;
  padding: 15px;
  cursor: pointer;
  /* justify-content: space-between; */
  align-items: flex-end;
}
.btn-ajuda-HelpPage p {
  text-align: left;
  font-size: 19px;
  color: #ffffff;
  margin-left: 60px;
}
@media only screen and (max-width: 800px) {
  .container-superior-HelpPage {
    display: flex;
    flex-direction: column;
  }

  .container-superior-HelpPage h1 {
    margin-bottom: 15px;
  }

  .btn-ajuda-HelpPage {
    display: flex;
    width: 100%;
  }
  .btn-voltar-helpPage {
    margin-left: 15px;
  }
}

.accordion__section_Help {
  display: flex;
  flex-direction: column;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion_Help {
  background-color: transparent;
  /* color: #5555; */
  cursor: pointer;

  display: flex;
  align-items: center;
  border: none;
  outline: none;
  /*  transition: background-color 0.6s ease; */
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion_Help:hover,
.active_Help {
  /*  background-color: #ccc; */
}

/* Style the accordion content title */
.accordion__title_Help {
  text-align: left;
  /* font: italic normal 600 16px/21px Segoe TV; */
  letter-spacing: 0px;
  color: #4b4b4b;
  margin-top: 20px;
}

/* Style the accordion chevron icon */
.accordion__icon_Help {
  margin-left: auto;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content_Help {
  background-color: white;
  overflow: auto;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text_Help {
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}

.container-Tracking {
  margin: 15px;
}

.container-Tracking h1 {
  text-align: left;
  /* font: italic normal 600 21px/28px Segoe TV; */
  letter-spacing: 0px;
  color: #ff5e00;
  margin-bottom: 60px;
}

.tracking-transporte {
  max-width: 1164px;
  height: 200px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 5px;

  margin-bottom: 20px;
}

.posicionamentoinfoTracking {
  display: flex;
  justify-content: space-between;
}

.dadosTracking {
  display: flex;
  flex-direction: column;
}
.dadosTracking h1 {
  margin-bottom: 1px;
  font-size: 18px;
  margin: 10px 0;
}

.dadosTracking h2 {
  text-align: left;
  letter-spacing: 0px;
  color: #424242;
  font-size: 12px;
  margin-bottom: 10px;
}
/* .perfilTracking img {
  height: 50px;
 
} */



.perfilTracking-img {
  border-radius: 100%;
  height: 110px;
  width: 110px;
  object-fit: cover;
  /* object-position: center; */
}

.dadosTracking div {
  text-align: left;
  /* font: italic normal 600 14px/19px Segoe TV; */
  font-size: 14px;
  color: #424242;
  opacity: 0.5;
  border: none;
}
.infoTracking {
  margin: 5px 0px 0px 15px;
}

.perfilTracking {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: space-between;
  text-align: center;
  margin-right: 20px;
  height: auto;
  max-height: 150px;
}

.nota-tracking {
  display: flex;
  width: 44px;
  height: 25px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  padding: 5px;
  justify-content: space-between;
  top: 4px;
}

.nota-tracking input {
  border: none;
  text-align: right;
  /*  font: italic normal 600 12px/16px Segoe TV; */
  font-size: 12px;
  letter-spacing: 0px;
  color: #505050;
  width: 20px;
}

.avaliacaoTracking {
  /*  top: -17px; */
  margin: -10px;
  display: flex;
}

.avaliacaoTracking {
  width: 46px;
  height: 320px;
  margin-top: -5px;
  margin-left: 3px;
}

.dadosTracking img {
  width: 100px;
  height: 19px;
}

.estrela {
  height: 15px;
  width: 20px;
  margin-right: 10px;
}

.coletaTracking-img {
  width: 1    09px;
  height: 50px;
  margin-bottom: 25px;
}
.coletaTracking-status {
  padding: 20px 0px;
}
.super-star-tracking img {
  height: 40px;
  margin-right: 10px;
}

.tracking-loader {
  width: 100%;
  display: flex;
  justify-content: center;
}

.tracking-loader img {
  position: absolute;
  z-index: 1000;
  margin-top: 15px;
  margin-right: 20px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  -webkit-animation:spin 6s linear infinite;
  animation:spin 6s linear infinite;

}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}


@media only screen and (max-width: 1280px) { 
  .perfilTracking {
    /* margin-left: 20%; */
  }


  .perfilTracking-img  {
    border-radius: 100%;
    height: 85px;
    width: 85px;
    object-fit: cover;
  }
  .tracking-transporte {
    height: auto;
    padding-bottom: 20px;
  }
  
  .tracking-loader img{
    position: static;
    position: initial;
    margin: 0;
  }
  

  .posicionamentoinfoTracking {
    
    flex-direction: column-reverse;
  }
}
.column-LostPass  {
  width: 40%;
}
.container-AccountScreen{
  margin-left: 100px;
}

.container-principal-LostPass {
  width: 100vw;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.container-description{
  margin-bottom: 20px;
}
.container-inferior-LostPass {
  display: flex;
  width: 100%;
  height: 40vh;
  background: transparent linear-gradient(180deg, var(--color-1) 0%, var(--color-2) 100%) 0%;
  /* flex: auto; */
  align-items: center;
}

.container-inferior-LostPass button {
  /* margin-left: -20px; */
  align-content: center;
  
}

.container-form {
  height: 500px;
  justify-content: space-between;
  margin-left: 115px;
  display: flex;
  align-items: center;
  margin-right: 115px;

}

.column-detail-LostPass img{
  /* display: flex; */
  align-items: center;
  align-self: center;
  /* justify-content: center; */
  margin-right: 100px;
}



@media only screen and (max-width: 1000px) {
  .container-AccountScreen{
    /* margin-left: -43px; */
    display: flex;
    width: 120px;
    align-items: center;
    justify-self: center;
    align-self: center;
    justify-content: space-evenly;
  }

  .container-AccountScreen button {
    width: 320px;
    align-items: center;
    
  }

  .container-form {
    margin: 0px;
    height: auto;
    z-index: 1;
   
  }

  .container-inferior-LostPass button {
    margin-right: 0;
    /* margin-left: 20px; */
  }

  .column-LostPass  {
    width: 80%;
    margin-left: 10%;
    justify-content: space-evenly;
    margin-bottom: 70px;
    margin-top: 70px;
  }

  .container-AccountScreen form {
    margin-left: 50%;
   }

  @media only screen and (max-width: 600px) {
    .container {
      padding: 20px;
    }
    .column.detail-LostPass{
      display: none;
    }
    .container-form-LostPass{
      margin: 0px;
      height: auto;
      z-index: 1;
      margin-bottom: 70px;
      margin-top: 70px;
    }
    .container-form form {
      width: 100%;
    }
    .container .column {
      width: 100%;
    }
    .container form input {
      width: 100%;
    }
  
    
  }
  
}
.column-PassRecovery  {
  width: 40%;
}
.container-AccountScreen{
  margin-left: 100px;
}

.container-principal-PassRecovery {
  width: 100vw;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.container-description{
  margin-bottom: 20px;
}
.container-inferior-PassRecovery {
  display: flex;
  width: 100%;
  height: 40vh;
  background: transparent linear-gradient(180deg, var(--color-1) 0%, var(--color-2) 100%) 0%;
  /* flex: auto; */
  align-items: center;
}

.container-inferior-PassRecovery button {
  /* margin-left: -20px; */
  align-content: center;
  
}

.container-form {
  height: 500px;
  justify-content: space-between;
  margin-left: 115px;
  display: flex;
  align-items: center;
  margin-right: 115px;

}

.column-detail-PassRecovery img{
  /* display: flex; */
  align-items: center;
  align-self: center;
  /* justify-content: center; */
  margin-right: 100px;
}



@media only screen and (max-width: 1000px) {
  .container-AccountScreen{
    /* margin-left: -43px; */
    display: flex;
    width: 120px;
    align-items: center;
    justify-self: center;
    align-self: center;
    justify-content: space-evenly;
  }

  .container-AccountScreen button {
    width: 320px;
    align-items: center;
    
  }

  .container-form {
    margin: 0px;
    height: auto;
    z-index: 1;
   
  }

  .container-inferior-PassRecovery button {
    margin-right: 0;
    /* margin-left: 20px; */
  }

  .column-PassRecovery  {
    width: 80%;
    margin-left: 10%;
    justify-content: space-evenly;
    margin-bottom: 70px;
    margin-top: 70px;
  }

  .container-AccountScreen form {
    margin-left: 50%;
   }

  @media only screen and (max-width: 600px) {
    .container {
      padding: 20px;
    }
    .column.detail-PassRecovery{
      display: none;
    }
    .container-form-PassRecovery{
      margin: 0px;
      height: auto;
      z-index: 1;
      margin-bottom: 70px;
      margin-top: 70px;
    }
    .container-form form {
      width: 100%;
    }
    .container .column {
      width: 100%;
    }
    .container form input {
      width: 100%;
    }
  
    
  }
  
}
.container-principal-trackingMap {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.itens-container-central-trackingMap {
  z-index: 1;
  margin: 35px;
  align-items: center;
}
.btn-trackingMap .accordion {
  min-width: inherit!important;
  max-width: inherit!important;
}
.btn-trackingMap .accordion p {
  margin-left: 0px;
}
.btn-voltar-trackingMap {
  margin-bottom: 30px;
  margin-left: -15px;
}
.infoTrackingMap {
  max-width: 500px;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  float: right;
  margin-top: 100px;
  padding: 30px;
}

.nota-trackingMap {
  width: 73px;
  height: 42px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 7px;
  top: -5px;
}
.nota-trackingMap input {
  border: none;
  width: 30px;
  font-size: 19px;
  font-weight: bold;
  color: #505050;
}
.posicionamento-infoTracking {
  display: flex;
  justify-content: space-between;
}
.dadosTrackingMap h1 {
  color: #ff5e00;
  margin-bottom: 5px;
  font-size: 22px;
}
.dadosTrackingMap input {
  margin-bottom: 5px;
  width: 200px;
  font-size: 23px;
  border: none;
  color: #424242;
  opacity: 0.5;
}
.dadosTrackingMap h2 {
  letter-spacing: 0px;
  color: #424242;
}

.dadosTrackingMap-subtitle {
  letter-spacing: 0px;
  font-size: 17px;
  color: #424242;
  font-weight: bold;
}
.perfilTrackingMap {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}
.coletaTrackingMap-profile-wrapper{
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}
.coletaTrackingMap-profile {
  border-radius: 100%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 10px;
}
.btn-trackingMap {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  max-height: 300px;
}
.avaliacaoTrackingMap {
  top: -30px;
  position: relative;
  align-items: center;
}
.steps-item {
  height: auto;
}

.container-qr-code-tracking-map {
  display: flex;
  justify-content: center;
}

.container-qr-code-tracking-map > img {
  width:  110px;
  height: 110px;
}

.trackingMap-actions {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.trackingMap-actions button{
  font-size: 16px;
  display: flex;
  transition-duration: 0.2s;
  background: var(--unnamed-color-303030) 0% 0% no-repeat padding-box;
  background: #ff5e00 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  text-align: left;
  color: #ffffff;
  opacity: 1;
  justify-content: space-between;
  align-self: center;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
  border: none;
  text-decoration: none;
}

@media only screen and (max-width: 500px) {
  .container-principal-trackingMap {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .dadosTrackingMap h1 {
    font-size: 20px;
    text-align: center;
  }
  .dadosTrackingMap h2 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
  }    
  .posicionamento-infoTracking {
    flex-direction: column-reverse;
  }

  .infoTrackingMap {
    display: flex;
    margin-top: 10px;
    float: none;
    flex-direction: column;
  }
  .btn-trackingMap {
    display: flex;
    margin-top: 50px;
  }

  .btn-trackingMap p {
    font-size: 17px;
  }
  .perfilTrackingMap {
    right:0;
    padding: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  
  .coletaTrackingMap {
    width: 100%;
    height: 20px;
    margin-bottom: 20px;
  }
  .dadosTrackingMap input {
    margin-bottom: 1px;
    font-size: 15px;
  }

  .container-qr-code-tracking-map {
    margin-top: 20px;
  }
  
}

/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  display: flex;
  transition-duration: 0.2s;
  width: 100%;
  max-width: 380px;
  height: 64px;

  background: var(--unnamed-color-303030) 0% 0% no-repeat padding-box;
  background: #ff5e00 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  text-align: left;
  color: #ffffff;
  opacity: 1;
  font-size: 20px;
  justify-content: space-between;
  align-self: center;
  padding: 20px 25px 10px 30px;
  cursor: pointer;
  border: none;
  text-decoration: none;
  min-width: 510px;
}

.accordion img {
  /* margin-left: 5px; */
  margin-left: 30px;
}
.accordion p {
  /* margin-left: 5px; */
  margin-left: 100px;
}
/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {
  border: 1px solid #e3e3e3;
}

/* Style the accordion content title */
/* .accordion__title {
  font-weight: 600;
  font-size: 14px;
  text-align: left;
}
 */
/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  margin-top: 20px;
  background-color: white;
  overflow: auto;
  transition: max-height 0.6s ease;

  height: 100%;
  max-height: 600px;
}

/* Style the accordion content text */
.accordion__text {
  margin-top: 20px;

  /* font-family: "Open Sans", sans-serif; */
  font-weight: 400;
  font-size: 10px;
  padding: 18px;
}


@media only screen and (max-width: 600px) {
  .btn-trackingMap .accordion{
    min-width: 100%;
  }

  .accordion img {
    /* margin-left: 5px; */
    margin-left: 20px;
  }
  .accordion p {
    /* margin-left: 5px; */
    margin-left: 0px;
  }
}
.gm-style-mtc {
  display: none;
}

.gm-control-active {
  display: none;
}

.container-map {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  /* z-index: 9999; */
}

.App {
  display: flex;
  font-family: sans-serif;
  border-radius: 10px;
  /* padding: 20px; */
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */

  min-height: auto;
}

/* .containerComponentTracking {
  width: 17%;
} */

.form-container {
  width: 50%;
}

.steps-container {
  display: grid;
  grid-row-gap: 40px;
  row-gap: 40px;
  grid-template-columns: 1fr;
}

.steps-item {
  font-size: 15px;
  min-height: 71px;
  margin-left: 17px;
  height: 30px;
  margin-top: 7px;
  margin-bottom: 10px;
}

.steps-item h3 {
  margin: 0;
  color: color;
}
.steps-item-date {
  font-size: 13px;
}

.vertical-slider {
  height: 100%;
  width: 25%;
  /* border: 1px solid grey; */
}


@media only screen and (max-width: 600px) {

  .steps-item {
    /* margin-left: 50px; */
    /* margin-top: 20px; */
  }
}
.example-mark {
  width: 8px;
  height: 8px;
  border: 2px solid rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
  margin: 0px calc(19px);
  bottom: calc(50% - 6px);
}

.example-mark.example-mark-active {
  border: 2px solid #ff5e00;
  background-color: rgb(255, 255, 255);
}

.example-mark.example-mark-completed {
  border: 2px solid rgb(255, 255, 255);
  background-color: #ff5e00;
}

.example-thumb {
  font-size: 0.9em;
  text-align: center;
  background-color: black;
  color: white;
  cursor: pointer;
  box-sizing: border-box;
}

.example-thumb.active {
  background-color: grey;
}

.example-track {
  position: relative;
  background: #ddd;
}

.example-track.example-track-0 {
  background: #ff5e00;
}

.vertical-slider .example-thumb {
  left: 1px;
  width: 48px;
  line-height: 40px;
  height: 50px;
}

.vertical-slider .example-track {
  left: 22px;
  width: 2px;
}


@media only screen and (max-width: 600px) {

  .vertical-slider .example-track {
    left: 0;
  }
}
.container-LegalInfoPage {
  margin: 15px;
}

.container-LegalInfoPage h1 {
  text-align: left;
  /*  font: italic normal 600 26px/35px Segoe TV; */
  letter-spacing: 0px;
  color: #ff5e00;
}

.container-LegalInfoPage p {
  text-align: justify;
  /* font: normal normal normal 14px/19px Segoe TV; */
  font-size: 14px;
  letter-spacing: 0px;
  color: #707070;

  margin-top: 20px;
  text-justify: center;
}

.container-principal {
  justify-content: center;
}
.container-central-notfound{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container-principal-map {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.container-full-map-page{
  position: relative;
  width: 1280px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-width: 100%;
  margin: 0 auto;
}

.container-inferior-map {
  display: flex;
  justify-content: space-between;
  margin-top: 65px;
}
.margin-Map {
  margin: 35px;
}

.btn-voltar-map {
  margin-bottom: 30px;
  margin-left: -15px;
}
.itens-container-central-map {
  z-index: 1;
  margin: 35px;
  align-items: center;
}
.input-map {
  margin-bottom: 300px;
}
.container-inferior-cinza-map {
  display: flex;
  width: 100%;
  height: 215px;
  max-height: 100%;
  background: transparent linear-gradient(180deg, #c9c9c9 0%, #e9e9e9 100%) 0%
    0% no-repeat padding-box;
  flex: auto;
  text-align: center;
  position: relative;
}

@media only screen and (max-width: 1000px) {
  .container-inferior-map {
    flex-direction: column;
    align-items: center;
  }
  /* Solves the  footer white area bug on mobile */
  .container-inferior-cinza-map {
    height: auto;
  }
  .container-full-map-page {
    padding-left: 20px;
    padding-right: 20px;
  }

  .transpSegurado-map {
    margin-bottom: 15px;
  }
  .input-map {
    margin-bottom: 0px;
  }
  .btn-voltar-map {
    display: flex;
  }
}

.canvas {
  /*   margin: 30px; */
  width: 100%;
}
.location-search-input {
  font-size: 17px;
}
.autocomplete-dropdown-container {
  width: 100%;
  font-size: 10px;
  position: absolute;
  display: flex;
  flex-direction: column;
  box-shadow: 0 8px 10px #00000038;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  margin-top: -14px;
}

.input-suggestion {
  align-items: center;
  padding: 10px;
  font-size: 17px;
}

.input-map-container {
  display: flex;
  justify-content: space-between;
}
.input-map-container-big {
  width: 70%; /*5% spaced*/
}
.input-map-container-medium {
  width: 48%; /*5% spaced*/
}
.input-map-container-small {
  width: 25%; 
}
.autocomplete-loading {
  background: #c8c8c8;
  padding: 8px;
  text-align: center;
  color: #fff;
  font-size: 12px;
}

.autocomplete-select {
  /* max-width: 464px; */
  width: 100%;
  height: 54px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  font-size: 25px;
  text-align: center;
  box-shadow: 0px 3px 6px #00000029;
  text-decoration: none;
  transition: all 0.3s ease-in-out;  
  text-align: center;
  margin-bottom: 13px;
}

/* Responsive maps on column style on mobile */
@media only screen and (max-width: 600px) {
  .input-map-container {
    flex-direction: column;
  }
  .input-map-container-big {
    width: 100%;
  }
  .input-map-container-small {
    width: 100%;
  }
  .input-map-container-medium {
    width: 100%;
  }
  .autocomplete-dropdown-container {
    width: 89%;
  }
}
.input input {
  width: 100%;
  max-width: 100%;

  height: 54px;
  border: none;
  font-size: 27px;
}
.input {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  margin-bottom: 13px;
  text-align: center;
  font-size: 25px;
  display: flex;
  align-items: center;
  padding: 0px 15px 0px 15px;
}

.shepherd-button {
  background: #FF5E00;
  border: 0;
  border-radius: 3px;
  color: hsla(0, 0%, 100%, .75);
  cursor: pointer;
  margin-right: .5rem;
  padding: .5rem 1.5rem;
  transition: all .5s ease
}

.shepherd-button:not(:disabled):hover {
  background: #fc843e;
  color: hsla(0, 0%, 100%, .75)
}

.shepherd-button.shepherd-button-secondary {
  background: #f1f2f3;
  color: rgba(0, 0, 0, .75)
}

.shepherd-button.shepherd-button-secondary:not(:disabled):hover {
  background: #d6d9db;
  color: rgba(0, 0, 0, .75)
}

.shepherd-button:disabled {
  cursor: not-allowed
}

.shepherd-footer {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: flex-end;
  padding: 0 .75rem .75rem
}

.shepherd-footer .shepherd-button:last-child {
  margin-right: 0
}

.shepherd-cancel-icon {
  background: transparent;
  border: none;
  color: hsla(0, 0%, 50%, .75);
  cursor: pointer;
  font-size: 2em;
  font-weight: 400;
  margin: 0;
  padding: 0;
  transition: color .5s ease
}

.shepherd-cancel-icon:hover {
  color: rgba(0, 0, 0, .75)
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
  color: hsla(0, 0%, 50%, .75)
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon:hover {
  color: rgba(0, 0, 0, .75)
}

.shepherd-title {
  color: rgba(0, 0, 0, .75);
  display: flex;
  flex: 1 0 auto;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  padding: 0
}

.shepherd-header {
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: flex-end;
  line-height: 2em;
  padding: .75rem .75rem 0
}

.shepherd-has-title .shepherd-content .shepherd-header {
  background: #e6e6e6;
  padding: 1em
}

.shepherd-text {
  color: rgba(0, 0, 0, .75);
  font-size: 1rem;
  line-height: 1.3em;
  padding: .75em
}

.shepherd-text p {
  margin-top: 0
}

.shepherd-text p:last-child {
  margin-bottom: 0
}

.shepherd-content {
  border-radius: 5px;
  outline: none;
  padding: 0
}

.shepherd-element {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
  max-width: 400px;
  opacity: 0;
  outline: none;
  transition: opacity .3s, visibility .3s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}

.shepherd-enabled.shepherd-element {
  opacity: 1;
  visibility: visible
}

.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered) {
  opacity: 0;
  pointer-events: none;
  visibility: hidden
}

.shepherd-element,
.shepherd-element *,
.shepherd-element :after,
.shepherd-element :before {
  box-sizing: border-box
}

.shepherd-arrow,
.shepherd-arrow:before {
  height: 16px;
  position: absolute;
  width: 16px;
  z-index: -1
}

.shepherd-arrow:before {
  /* border: 1px solid #FF5E00; */
  background: #FF5E00;
  content: "";
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.shepherd-element[data-popper-placement^=top]>.shepherd-arrow {
  bottom: -8px
}

.shepherd-element[data-popper-placement^=bottom]>.shepherd-arrow {
  top: -8px
}

.shepherd-element[data-popper-placement^=left]>.shepherd-arrow {
  right: -8px
}

.shepherd-element[data-popper-placement^=right]>.shepherd-arrow {
  left: -8px
}

.shepherd-element.shepherd-centered>.shepherd-arrow {
  opacity: 0
}

.shepherd-element.shepherd-has-title[data-popper-placement^=bottom]>.shepherd-arrow:before {
  background-color: #FF5E00
}

.shepherd-target-click-disabled.shepherd-enabled.shepherd-target,
.shepherd-target-click-disabled.shepherd-enabled.shepherd-target * {
  pointer-events: none
}

.shepherd-modal-overlay-container {
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all .3s ease-out, height 0ms .3s, opacity .3s 0ms;
  width: 100vw;
  z-index: 9997
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  height: 100vh;
  opacity: .5;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  transition: all .3s ease-out, height 0s 0s, opacity .3s 0s
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible path {
  pointer-events: all
}
/* Alert UI */
.MuiDialog-paperWidthSm {
    border-top: 5px solid var(--color-1);
}

/* Custom Alert */
/* Alert box */
.alert{
    border: 0;
    border-radius:5px;
    background: #fff;
    padding: 30px;
    display: flex;
    box-shadow: 0 0 10px #00000010;
    border-top:5px solid #f0f0f0;
}
/* Alert types */
alert.info{
    border-top:5px solid #ff9800;
}
alert.success{
    border-top:5px solid #4caf50;
}
alert.error{
    border-top:5px solid #fc2803;
}
/* Font */

/* Configs */
:root {
  --color-1: #ff5e00;
  --color-2: #f5812b;
  --color-3: #303030;
  --color-text: #666;
  --color-white: #fff;
  --color-red: #f00;
  --color-gray-1: #f0f0f0;
  --color-gray-2: #c0c0c0;
  --color-gray-3: #75757509;
  --color-black-1: #333;
  --color-black-2: #000;
  --color-green-1: #6edc25;
  --font: "Noto Sans", sans-serif;
  --shadow-input: 0px 0px 5px rgba(0, 0, 0, 0.2);
  --shadow-input-hover: 0px 0px 2px rgba(0, 0, 0, 0.5);
  --radius: 4px;
}

body,
* {
  font-family: "Noto Sans", sans-serif;
  font-family: var(--font);
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}
body {
  font: 400 14px "Noto Sans", sans-serif;
  font: 400 14px var(--font);
  background: #fff;
  -webkit-font-smoothing: antialiased;
}

/* Scrolls */
::-webkit-scrollbar{
	width: 12px;
	background-color: #F5F5F5;
	/* Transition */	
	-webkit-transition: all 0.3s ease-in-out;	
	transition: all 0.3s ease-in-out;	
}
::-webkit-scrollbar-thumb {
	border-radius: 0px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}
::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 0px;
	background-color: #F5F5F5;
	cursor:default;
	/* Transition */	
	-webkit-transition: all 0.3s ease-in-out;	
	transition: all 0.3s ease-in-out;		
}

h1 {
  color: #ff5e00;
  margin-bottom: 20px;
}
h2,h3,h4,h5 {
  font-weight: normal;
}
/* Page Structure */
.container {
  position: relative;
  width: 1280px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-width: 100%;
  margin: 0 auto;
}
.container-principal {
  width: 100vw;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.container-form {
  height: 500px;
  justify-content: space-between;
  margin-left: 115px;
  display: flex;
  align-items: center;
}
.container .container-description {
  color: #000;
  color: var(--color-black-2);
  margin-bottom: 15px;
  font-size: 18px;
}
.container .container-form form {
  width: 100%;
  display: block;
}
.container-inferior {
  width: 100%;
  height: 215px;
  background: transparent linear-gradient(180deg, #ff5e00 0%, #f5812b 100%) 0%;
  background: transparent linear-gradient(180deg, var(--color-1) 0%, var(--color-2) 100%) 0%;
  flex: auto;
}
.container-inferior button {
  float: right;
  margin-top: 70px;
  margin-right: 90px;
}
.container .column {
  width: 50%;
}
.container .column.detail {
  flex: 1 1;
  display: flex;
  justify-content: center;
}
.container .column.detail img {
  max-width: 50%;
  max-height: 50%;
}

/* Form globals */
::-webkit-input-placeholder {
  color: #c4c4c4;
}
input[type="button"],
input[type="reset"],
input[type="submit"],
button {
  cursor: pointer;
  border: none;  
  box-shadow: 0px 3px 6px #00000029;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover,
button:hover {
  box-shadow: 0px 1px 2px #00000029;
}
/* Inputs errors  */
.inputField span, 
.inputError {
  display: block;
  position: absolute;
  background: #000;
  color: #fff!important;
  font-size: 12px;
  padding: 5px 10px;
  margin: 50px 0px 0px 10px;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  z-index: 999;
}


/* Toastify alert customization  */
.Toastify__toast {
  margin-top: 5px;
}
@media only screen and (max-width: 800px) {
  .Toastify__toast-container--top-center, 
  .Toastify__toast-container--top-left, 
  .Toastify__toast-container--top-right {
    top: 5%;
    width: 90%;
    margin: 5%;
  }    
}

@media only screen and (max-width: 800px) {
  .container-inferior button {
    /* float: right; */
    margin-top: 30px;
    margin-right: 0;
    margin-left: 20px;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 20px;
  }  
  .container-form {
    margin: 0px;
    height: auto;
    z-index: 1;
    margin-bottom: 50px;
  }

  
  .container-form form {
    width: 100%;
  }
  .container .column {
    width: 100%;
  }
  .container form input {
    width: 100%;
  }
  .container-inferior button {
      margin-left: -20px;
  }
  .container-inferior form {
    margin-left: 30px;
  }
}

