.container-principal-OrderResume {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.container-OrderResume {
  margin: 14px;
}
.container-OrderResume-inferior {
  display: flex;
  width: 100%;
  height: 215px;
  background: transparent linear-gradient(180deg, #c9c9c9 0%, #e9e9e9 100%) 0%
    0% no-repeat padding-box;
  flex: auto;
}

.cx-info-transp, 
.cx-info-transp-central{
  width: 600px;
  height: 310px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  /*  margin-left: 10px; */
  display: flex;
  padding: 35px 30px;
  cursor: pointer;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
}
.cx-info-transp-central{
  padding-top:0;
}

/* checkbox desktop css */
.cx-info-transp-checkbox {
  width: 100%;
  min-height: 100px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  /*  margin-left: 10px; */
  display: flex;
  padding: 35px 30px;
  cursor: pointer;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.opcoes-transp-content-checkbox {
  display: flex;
  align-items: center;
}
.opcoes-transp-content-checkbox > h4 {
  margin-left: 20px;
}

.container-resumo-transp {
  display: flex;
  width: 100%;
  margin-top: 47px;

  justify-content: space-between;
}
.container-resumo-transp h4 {
  margin-left: 10px;
  color: #303030;
}

.container-central-OrderResume h1 {
  color: #ff5e00;
  font-size: 24px;
  text-align: center;
  margin-bottom: 45px;
}
.container-central-OrderResume img {
  cursor: pointer;
}
.container-central-OrderResume h3 {
  margin-bottom: 10px;
  font-size: 16px;
  height: auto;
}
.container-central-OrderResume {
  margin-top: 10px;
  width: 100%;
  height: auto;
  height: auto;
  margin-bottom: 30px;
}
.switch-OrderResume {
  display: flex;

  
}

.switch-OrderResume h4 {
  margin-bottom: 15px;
  margin-left: 13px;
  margin-top: 15px;
}

.timer {
  display: flex;
  font-size: 16px;
  color: #ff5e00;
  align-items: center;
}
.timer img {
  width: 16px;
  margin-right: 10px;
}
.timer input {
  width: 200px;
  height: 40px;
  border: none;
  background-color: transparent;
  text-align: right;
  /* font: italic normal 600 21px/41px Segoe TV; */
  font-size: 21px;
  letter-spacing: 0px;
  color: #303030;
  font-weight: bold;
}
.total {
  display: flex;
  justify-content: space-between;
  /* font: italic normal 600 32px Segoe TV; */
  font-size: 32px;
  color: #ff5e00;
  align-items: center;
}

.total input placeholder {
  text-align: right;
  font-weight: bold;
}
.total span {
  width: 100px;
  height: 49px;
  border: none;
  background-color: transparent;
  font-size: 32px;
  align-self: center;
  text-align: right;
  /* font: italic normal 600 37px/49px Segoe TV; */
  font-size: 37px;
  letter-spacing: 0px;
  color: #303030;
  font-weight: bold;
  margin-left: 20px;
}

.switch-OrderResume {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.switch-OrderResume img {
  margin-left: 100px;
}
hr{
  border: 1px solid #ff5e00;
  height: 0px;
  padding:0;
}
.opcoes-transp {
  align-items: center;
  margin-bottom: 35px;
  display: flex;
}
.opcoes-transp input {
  margin-left: 20px;
  border: none;
  /*  font: italic normal 600 14px/19px Segoe TV; */
  font-size: 14px;
  width: 100%;
  font-weight: bold;
}
.opcoes-transp-opcionais {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.opcoes-transp-opcionais h5 {
  margin-left: 10px;
}
.img-interrogacao {
  margin-left: auto;
}

.img-confirmar-OrderResume {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}
.posicionamento-order-resume {
  display: flex;
  justify-content: space-between;
}

.lista-item-OrderResume {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 17px;
}
.lista-item-OrderResume-input-name {
  align-items: center;
  color: #303030;
  font-weight: bold;
  width: 100%;
  font-size: 16px;
}

lista-item-OrderResume img {
  margin-left: 30px;
}

lista-item-OrderResume-observations {
  text-align: left;
}

.lista-item-OrderResume-input-number {
  width:40px;
  height: 40px;
  text-align: center;
  color:  #ff5e00;
  overflow-x: hidden;
  display: inline-flex;
  align-items: center;
  align-content: center;
  align-self: center;
  font-size: 20px;
  font-weight: bold;
}

.cx-info-transp-central img {
  margin-left: 30px;
}

.opcoes-transp input{
  font-size: 24px;
  font-weight: normal;
}
.local-trans{
  color: #303030;
  margin-left: 20px;
  text-align: left;
  font-size: 16px;
}

span.totalLoading {
  padding: 0;
  margin: 0;
  color: #666;
}

.react-tool-tip {
  position: absolute !important;
}

.button-chat{
  margin-top: 30px;
  margin-bottom: 10px;
  text-align: center;
  margin-bottom: 20px;
}
  .button-chat p{
    margin-bottom: 10px;
  }

@media only screen and (max-width: 1100px) {
  .container-resumo-transp {
    flex-direction: column;
    align-items: center;
    /* text-align: center; */
  }
  .container-central-OrderResume h3 {
    text-align: center;
    font-size: 22px;
  }
  .cx-info-transp {
    width: 350px;
    margin-bottom: 30px;
    overflow: hidden;
  }
  .container-central-OrderResume h1 {
    margin-bottom: 23px;
    margin-top: 30px;
  }
  .posicionamento-order-resume {
    justify-self: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;
  }
  .timer h3 {
    font-size: 15px;
  }
  .switch-OrderResume {
    justify-content: center;
    margin-left: 15px;
  }
  .posicionamento-order-resume div {
    margin-bottom: 15px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .img-confirmar-OrderResume {
    justify-content: flex-start;
  }

  .total span {
    /* margin: auto, -32px; */
    text-align: center;
    width: 200px;
    margin: 0;
  }

  .cx-info-transp-central {
    margin-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: 350px;
    max-height: 500px;
    height: auto;
  }

  .cx-info-transp-central li {
    font-size: 14px;
    overflow: hidden;
    
  }

  .cx-info-transp-central input {
    font-size: 15px;

 
  }

  .cx-info-transp-central-div-span {
    font-size: 15px;
    /* padding: 0; */
 
  }

  /* checkbox mobile css */
  .cx-info-transp-checkbox {
    width: 350px;
    max-height: 500px;
  }

  .lista-item-OrderResume-input-number {
    /* margin-left: 30px; */
    width:40px;
    height: 35px;
    /* border: 1px solid #E3E3E3; */
    text-align: center;
    color:  #ff5e00;
    display: inline-flex;
    align-items: center;
    align-content: center;
    align-self: center;
    font-size: 20px;
    font-weight: bold;
  }

  .lista-item-OrderResume-input-number input {
    color:  #ff5e00;
    font-size: 20px;

  }


  .lista-item-OrderResume-observations {
    /* text-align: center; */
    margin-left: 30px;
  }

  .container-OrderResume-inferior {
    height: 100%;
  }

  .img-confirmar-OrderResume {
    justify-content: center;
  }

  .total {
    margin-left: 0;
  }

  .switch-OrderResume h4 {
    font-size: 21px;
  }
  
} 


@media only screen and (max-width: 1280px) { 
  
}
