.gm-style-mtc {
  display: none;
}

.gm-control-active {
  display: none;
}

.container-map {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  /* z-index: 9999; */
}
