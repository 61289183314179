.input input {
  width: 100%;
  max-width: 100%;

  height: 54px;
  border: none;
  font-size: 27px;
}
.input {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  margin-bottom: 13px;
  text-align: center;
  font-size: 25px;
  display: flex;
  align-items: center;
  padding: 0px 15px 0px 15px;
}
