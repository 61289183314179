.canvas {
  /*   margin: 30px; */
  width: 100%;
}
.location-search-input {
  font-size: 17px;
}
.autocomplete-dropdown-container {
  width: 100%;
  font-size: 10px;
  position: absolute;
  display: flex;
  flex-direction: column;
  box-shadow: 0 8px 10px #00000038;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  margin-top: -14px;
}

.input-suggestion {
  align-items: center;
  padding: 10px;
  font-size: 17px;
}

.input-map-container {
  display: flex;
  justify-content: space-between;
}
.input-map-container-big {
  width: 70%; /*5% spaced*/
}
.input-map-container-medium {
  width: 48%; /*5% spaced*/
}
.input-map-container-small {
  width: 25%; 
}
.autocomplete-loading {
  background: #c8c8c8;
  padding: 8px;
  text-align: center;
  color: #fff;
  font-size: 12px;
}

.autocomplete-select {
  /* max-width: 464px; */
  width: 100%;
  height: 54px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  font-size: 25px;
  text-align: center;
  box-shadow: 0px 3px 6px #00000029;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;  
  text-align: center;
  margin-bottom: 13px;
}

/* Responsive maps on column style on mobile */
@media only screen and (max-width: 600px) {
  .input-map-container {
    flex-direction: column;
  }
  .input-map-container-big {
    width: 100%;
  }
  .input-map-container-small {
    width: 100%;
  }
  .input-map-container-medium {
    width: 100%;
  }
  .autocomplete-dropdown-container {
    width: 89%;
  }
}