.modalContainer {
  width: 375px;
  height: 100%;
  background: transparent linear-gradient(180deg, var(--color-1) 0%, var(--color-2) 100%) 0%
    0% no-repeat padding-box;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  right: 0px;
  top: 0px;
  position: fixed;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;  
  /** Some layouts can break and you can't logout, overflow fix it*/
  overflow: hidden;
  overflow-x: hidden;
  /** In order to keep the bar always in the top view, preventing bug in map pages  */
  z-index: 9999;

}
.modalContainer.off {
  right: -400px;
}
.modalContainer.on {
  right: 0px;
}
.titleCloseBtn {
  position: relative;
  left: 85%;  
  display: flex;
  margin-top: 15px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;    
  cursor: pointer;
  transform: scale(1.0);
}
.titleCloseBtn:hover {
  transform: scale(1.1);
  left: 90%; 
}
.perfil-menu-modal {
  text-align: center;
}

.profile-image-menu-modal {
  display: inline-block;
  width: 250px;
  height: 250px;
  border-radius: 50%;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.dados-menu-modal {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -3px 6px #00000029;
  border-radius: 10px 10px 0px 0px;

  top: 307px;
  padding: 15px;
  position: absolute;
  width: 375px;
  height: 100%;
}
.border-info-modal {
  width: 340px;
  border-top: 1px solid #00000017;
  border-bottom: 1px solid #00000017;
  padding: 25px 10px 10px 10px;
  padding-bottom: 20px;
}
.info-menu-modal a {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: var(--color-3);
  font-weight: bold;
  margin-top: 4px;
  display: block;
  text-decoration: none;
  opacity: 0.5;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;    
}
.info-menu-modal a:hover {
  color: var(--color-1);
  text-decoration: none;
  opacity: 1;
}
.dados-menu-modal h2 {
  margin-top: 43px;
  margin-bottom: 20px;
  text-align: center;
  letter-spacing: 0px;
  color: var(--color-1);
}
.version {
  margin-top: 5px;
  text-align: center;
  font-size: 12px;
  color: #303030;
  opacity: 0.4;
}

/* controls tour slider */
.action-tour-modal-menu {
  margin-top: 35px;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
}
