.backgroundModal-ModalBase {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.89);
  position: fixed;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  display: flex;
  flex-direction: column;
}

.title-ModalBase {
  max-width: 370px;
  height: 250px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #fff;
  border-radius: 4px;
  margin-top: 60px;
  padding: 20px;
  display: flex;
  align-items: center;
}

.title-ModalBase span {
  font-weight: bold;
  cursor: pointer;
}
.btn-Modal {
  /* margin-top: 35px; */
}
.perfilModal {
  display: flex;
  justify-content: center;
}

.conteudoModal- {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
 
}

.actions-ModalBase {
  min-width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.conteudoModal-Base h2 {
  /* font: italic normal 600 20px Segoe TV; */
  color: #ff5e00;
  text-align: center;
  /* margin-top: 20px; */
  /* margin-bottom: 10px; */
}
.conteudoModal-Base h3 {
  color: var(--unnamed-color-303030);
  /*  font: italic normal 600 15px/21px Segoe TV; */
  color: #303030;
  text-align: center;
}

.buttonModal-Base {
  margin-top: 15px;
  color: white;
  background-color: #ff5e00;
  padding: 10px;
  border-radius: 5px;
}

.cancel-button-ModalBase {
  margin-top: 15px;
  color: white;
  background-color: #303030;
  padding: 10px;
  border-radius: 5px;
}

.cancel-button-ModalBase:hover {
  background-color: #3030309d;
}

.buttonModal-Base:hover {
  background-color: #fc9b62;

}

.conteudoModal-Base h4 {
  color: #838383;
}

