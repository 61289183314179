.btnCriarConta {
  font-size: 26px;
  width: 330px;
  height: 60px;
  transition-duration: 0.2s;
  background: var(--color-white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  border-radius: 5px;
  color: #5a5a5a;
  padding: 10px 30px;
  cursor: pointer;
}
.btnCriarConta:hover {
  box-shadow: 0px 1px 2px #00000029;
}
.criarConta {
  margin-right: 10px;
}
@media only screen and (max-width: 800px) {
  .btnCriarConta {
    width: 290px;
  }
}