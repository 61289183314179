.status {
  background-color: var(--color-gray-2);
  border-radius: 5px;
  padding: 5px;
  color: var(--color-white);
  padding: 5px 20px;
  box-shadow: 1px 1px 10px #0000004a;
  text-align: center;
  min-width: 150px;
}

/* Alert order status */
/* Novo */
.status-0{
  color: #bdf7ff;
  background-color: #095df0;
  border-color: #bedaf1;
}
/* Confirmado */
.status-1{
  color: #c4ffea;
  background-color: #29906b;
  border-color: #bff0de;
}
/* Em coleta */
.status-10{
  color: #cdc9ff;
  background-color: #4242ff;
  border-color: #b8c5f8;
}
/* Em entrega */
.status-20{
  color: #e5baff;
  background-color: #914ce0;
  border-color: #efb4fc;
}
/* Cancelado */
.status-30{
  color: #3b3b3b;
  background-color: #e3e3e3;
  border-color: #dbd5d5;
}
/* Adiado */
.status-40{
  color: #765901;
  background-color: #ffeec8;
  border-color: #ffe6b0;
}
/* Finalizado */
.status-50{
  color: #dbdbdb;
  background-color: #383838;
  border-color: #d7d7d7;
}
/* Pagamento negado / nao realizado */
.status-2001{
  color: #fff;
  background-color: #ff4141;
  border-color: #f40000;
}