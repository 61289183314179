.btnSejaMotorista {
  font-size: 18px;
  width: 330px;
  height: 60px;
  transition-duration: 0.2s;
  background: var(--color-black-1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  border-radius: 5px;
  color: var(--color-white);
  padding: 10px 30px;
  cursor: pointer;
}
.btnSejaMotorista:hover {
  box-shadow: 0px 1px 2px #00000029;
}
.SejaMotorista {
  margin-right: 10px;
}
@media only screen and (max-width: 800px) {
  .btnSejaMotorista {
    width: 290px;
  }
}