.example-mark {
  width: 8px;
  height: 8px;
  border: 2px solid rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
  margin: 0px calc(19px);
  bottom: calc(50% - 6px);
}

.example-mark.example-mark-active {
  border: 2px solid #ff5e00;
  background-color: rgb(255, 255, 255);
}

.example-mark.example-mark-completed {
  border: 2px solid rgb(255, 255, 255);
  background-color: #ff5e00;
}

.example-thumb {
  font-size: 0.9em;
  text-align: center;
  background-color: black;
  color: white;
  cursor: pointer;
  box-sizing: border-box;
}

.example-thumb.active {
  background-color: grey;
}

.example-track {
  position: relative;
  background: #ddd;
}

.example-track.example-track-0 {
  background: #ff5e00;
}

.vertical-slider .example-thumb {
  left: 1px;
  width: 48px;
  line-height: 40px;
  height: 50px;
}

.vertical-slider .example-track {
  left: 22px;
  width: 2px;
}


@media only screen and (max-width: 600px) {

  .vertical-slider .example-track {
    left: 0;
  }
}