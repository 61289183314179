.container-principal-productAdd {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.container-productAdd {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  background: white;
  z-index: -1;
}

.positionItems-ProductsAdd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}
.positionItems-ProductsAdd input {
  height: 70px;
  border: none;
  font-size: 28px;
  text-align: center;
}
.btn-voltar-productAdd {
  margin: 15px 0px 10px 15px;
}
.container-inferior-cinza-productAdd {
  display: flex;
  width: 100%;
  max-height: 100%;
  background: transparent linear-gradient(180deg, #c9c9c9 0%, #e9e9e9 100%) 0%
    0% no-repeat padding-box;
  flex: auto;
  text-align: center;
}
.btn-productAdd {
  display: flex;
}

.btn-productAdd button {
  width: 90px;
  background: #303030 0% 0% no-repeat padding-box;
  box-shadow: #00000029;
  border-radius: 3px;
  cursor: pointer;
}
.container-central-productAdd {
  display: flex;
  justify-content: space-between;
}
.campo-dimensoes-productAdd {
  display: flex;
  justify-content: space-between;
  margin-left: 28px;
}

.campo-dimensoes-productAdd input {
  width: 45px;
  height: 30px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
}
.campo-dimensoes-productAdd h5 {
  color: #ff5e00;
}

.container-items-productAdd {
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.linha-vertical {
  border-left: 2px solid #e3e3e3;
}
.container-observacoes {
  width: 100%;
}
.container-observacoes textarea {
  width: 100%;
  height: 307px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  font-size: 22px;
  padding: 10px;
  resize: none;
  max-width: 100%;
}
.container-observacoes textarea::placeholder {
  padding: 4px 0px 0px 8px;
}
.container-observacoes h1 {
  color: #ff5e00;
  font-size: 28px;
  margin-bottom: 30px;
}
.margin-productAdd {
  margin: 15px;
}
.imgDimensoes {
  margin-top: 43px;
  margin-left: 36px;
}
.input-dimensoes {
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
}
.input-dimensoes input {
  text-align: center;
}


.input-dimensoes-error {
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
}
.input-dimensoes-error input {
  text-align: center;
  border: 3.5px solid rgb(231, 147, 147);
}

.img-busca-productAdd {
  z-index: 9999;
  position: absolute;
  color: #303030;
  padding: 17px 0px 0px 140px;
}
.input-ProductsAdd {
  max-width: 370px;
  margin-left: 14px;
  max-width: 364px;
}
h3.productsAddName {
  margin-bottom: 20px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  font-size: 22px;
  padding: 10px;
}

@media only screen and (max-width: 1280px) {
  .container-central-productAdd {
    flex-direction: column;
    text-align: center;
  }
  .positionItems-ProductsAdd {
    flex-direction: column;
    text-align: center;
  }
  .positionItems-ProductsAdd {
    margin-top: none;
  }
  .positionItems-ProductsAdd input {
    width: 100%;
    min-width: 300px;
    max-width: 180px;
  }

  .positionItems-ProductsAdd button {
    width: 100%;
  }

  .container-observacoes {
    margin-top: 40px;
  }

  .input-dimensoes {
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
  }
  .btn-productAdd button {
    width: 120px;
  }
  .positionItems-ProductsAdd input {
    min-width: 90px;
    max-width: 70%;
  }

  .imgDimensoes {
    margin-top: 43px;
    text-align: left;
    /* margin-left: 20px; */
  }
  .btn-productAdd {
    margin: 23px 0;
  }

  .campo-dimensoes-productAdd {
    margin-left: -12px;
  }

  .container-observacoes {
    border-left: none;
    margin-left: 0px;
  }

  .container-observacoes h1 {
    text-align: center;
  }

  .container-observacoes textarea {
    padding: 15px;

  }
  .container-observacoes h1 {
    margin-left: 0px;
  }
  .btn-voltar-productAdd {
    margin-left: 28px;
  }
  .margin-productAdd {
    margin: 0px;
  }

.button-custom {
  margin-bottom: 22px;
  max-width: 100%;
  text-align: center;
}

.margin-productAdd h1 {
  text-align: center;
}
 /* .container-controllers{
   display: flex;
 } */

}
